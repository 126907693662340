import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import styled from 'styled-components';
import { useAuth } from './AuthContext'; // Importing useAuth for authentication
import { NavLink } from 'react-router-dom';


// Styled Components
const Container = styled.div`
  background-color: #000033;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  color: #fff;
  
  @media (max-width: 576px) {
    padding: 10px;
  }

  @media only screen and (max-width: 425px) {
    padding: 8px;
  }

  @media only screen and (max-width: 375px) {
    padding: 5px;
  }
`;

const ScrollableTableContainer = styled.div`
  width: 100%;
  overflow-x: auto; /* Horizontal scroll for smaller screens */
  margin-top: 20px;

  @media (max-width: 576px) {
    margin-top: 15px;
  }

  @media (max-width: 425px) {
    margin-top: 10px;
  }

  @media (max-width: 375px) {
    margin-top: 8px;
  }
`;

const Table = styled.table`
  width: 100%;
  min-width: 600px; /* Minimum width for better scrolling */
  border-collapse: collapse;
  font-size: 1rem;

  th, td {
    border: 1px solid #fff;
    padding: 12px;
    text-align: left;
  }

  th {
    background-color: #f0e00f;
    color: #000;
  }

  @media (max-width: 992px) {
    font-size: 0.9rem;
  }

  @media (max-width: 576px) {
    font-size: 0.8rem;
    padding: 8px;
  }

  @media only screen and (max-width: 425px) {
    font-size: 0.75rem;
    padding: 6px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 0.7rem;
    padding: 5px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  flex-wrap: wrap;

  h1 {
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
    
    h1 {
      font-size: 1.3rem;
    }
  }
`;

const ActionButton = styled(Button)`
  font-size: 0.9rem;
  padding: 6px 12px;
  margin: 5px;
  background-color: ${props => (props.variant === 'complete' ? '#28a745' : '#f0e00f')};
  color: #000033;
  border: none;

  &:hover {
    background-color: ${props => (props.variant === 'complete' ? '#218838' : '#e0d00f')};
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 5px 10px;
  }

  @media (max-width: 576px) {
    font-size: 0.75rem;
    padding: 4px 8px;
  }
`;

const WithdrawButton = styled(Button)`
  font-size: 0.9rem;
  padding: 6px 12px;
  margin: 5px;
  background-color: ${props => (props.variant === 'complete' ? '#28a745' : '#f0e00f')};
  color: #000033;
  border: 2px solid #fff;
  font-weight:bolder;
  &:hover {
    background-color: ${props => (props.variant === 'complete' ? '#218838' : '#e0d00f')};
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 5px 10px;
  }

  @media (max-width: 576px) {
    font-size: 0.75rem;
    padding: 4px 8px;
  }
`;

const InfoSection = styled.div`
  margin: 20px 0;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }

    @media (max-width: 576px) {
      font-size: 1rem;
    }
  }

  p {
    margin: 5px 0;
    font-size: 1rem;

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }

    @media (max-width: 576px) {
      font-size: 0.85rem;
    }
  }
`;



// Main Component
const ManageWithdrawal = () => {
//   const [userData, setUserData] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [withdrawalAmount, setWithdrawalAmount] = useState('');
//   const [selectedWallet, setSelectedWallet] = useState(null);
  
//   // Use AuthContext to get the authenticated user
//   const { state } = useAuth();
//   const user = state?.user?.user;
//   const userId = user?.id; // Extract userId from the authenticated user

//   // Fetch User Data
//   useEffect(() => {
//     fetchUserData();
//   }, []);

//   const fetchUserData = async () => {
//     try {
//     //   const response = await axios.get('https://example.com/api/user-withdrawal-details');
//       const response = await axios.get(`https://server.digitalspayout.com/api/crypto/wallet/user-wallet-subscription-details/${userId}`);

//       setUserData(response.data.data);
//     } catch (error) {
//       console.error('Error fetching user data:', error);
//     }
//   };

//   const handleWithdraw = (wallet) => {
//     setSelectedWallet(wallet);
//     setWithdrawalAmount('');
//     setShowModal(true);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       // Make withdrawal request with the selected wallet and withdrawalAmount
//       const withdrawalData = {
//         userId: userId, // From AuthContext
//         walletId: selectedWallet.walletId,
//         amount: withdrawalAmount,
//         currencyId: selectedWallet.deposit.currencyId // Assuming currencyId is available from the deposit
//       };

//       await axios.post('https://server.digitalspayout.com/api/crypto/payout/request', withdrawalData);
//       setShowModal(false);
//       fetchUserData(); // Refresh the data after withdrawal
//     } catch (error) {
//       console.error('Error processing withdrawal:', error);
//     }
//   };
const [userData, setUserData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState('');
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [selectedWalletId, setSelectedWalletId] = useState(null);
  const [selectedCurrencyId, setSelectedCurrencyId] = useState(null);
  

  const { state } = useAuth();
  const user = state?.user?.user;
  const userId = user?.id; // Extract userId from the authenticated user

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`https://server.digitalspayout.com/api/crypto/wallet/user-wallet-subscription-details/${userId}`);
       console.log('response:', response.data.data);
       
      setUserData(response.data.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleWithdraw = (subscription, wallet) => {
    setSelectedSubscription(subscription);
    setSelectedWallet(wallet);
    setWithdrawalAmount('');
    setWithdrawalAmount(''); // Reset withdrawal amount
    setShowModal(true);
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Selected Wallet for Withdrawal:', selectedWallet); // Check walletId here
    if (!selectedWallet || !selectedWallet.walletId) {
        alert('No wallet selected or walletId is undefined');
        return;
    }
    
    try {
      // Make withdrawal request with the selected wallet and withdrawalAmount
      const withdrawalData = {
        userId: userId, // From AuthContext
        walletId: selectedWallet.walletId,
        amount: withdrawalAmount,
        currencyId: selectedWallet.deposit.CurrencyId // Using CurrencyId from the deposit
      };
  console.log('withdrawalData before:', withdrawalData);
  
      const response = await axios.post('https://server.digitalspayout.com/api/crypto/payout/request', withdrawalData);
     console.log('withdrawalData:', withdrawalData);
     
      setShowModal(false);
      alert(response.data.message); // Show success or error message
      fetchUserData(); // Refresh the data after withdrawal
    } catch (error) {
      console.error('Error processing withdrawal:', error);
      alert('Withdrawal request failed. Please try again.'); // Alert on error
    }
  };


    // Function to calculate total deposited amount
    const calculateTotalDeposited = () => {
      if (!userData || !userData.wallets) return '0.00'; // Return '0.00' if no user data
  
      const totalDeposited = Object.keys(userData.wallets).reduce((total, walletType) => {
        const wallets = userData.wallets[walletType];
        return total + wallets.reduce((walletTotal, wallet) => {
          const depositAmount = wallet.deposit?.depositAmount; // Get deposit amount
          return walletTotal + (typeof depositAmount === 'number' ? depositAmount : 0); // Ensure it's a number, else treat as 0
        }, 0);
      }, 0);
  
      return totalDeposited > 0 ? totalDeposited.toFixed(2) : '0.00'; // Return formatted amount or '0.00'
    };
  

  return (
    <Container>
      <Header>
        <h1>Manage Withdrawals</h1>
      </Header>

      {userData ? (
        <>
          {/* User Info */}
          <InfoSection>
            <h3>User Information</h3>
            <p><strong>Name:</strong> {user?.firstName} {user?.lastName}</p>
            <p><strong>Email:</strong> {user?.email}</p>
            <p><strong>Phone:</strong> {user?.phone}</p>
          </InfoSection>

          {/* Wallets */}
          <h3>Wallet Details</h3>
          {Object.keys(userData.wallets).map((walletType) => (
            <div key={walletType} >
              <h4>{walletType.charAt(0).toUpperCase() + walletType.slice(1)} Wallet</h4>
              {userData.wallets[walletType].map((wallet) => (
                <ScrollableTableContainer key={wallet.walletId}>
                <Table key={wallet.walletId}>
                  <thead>
                    <tr>
                      <th>Wallet Address</th>
                      {/* <th>currencyId</th> */}
                      {/* <th>WalletId</th> */}
                      <th>Wallet Balance</th>
                      <th>Deposit Amount</th>
                      <th>Deposit currencyName</th>
                      <th>Deposit currency Unit</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{wallet.walletAddress}</td>
                      {/* <td>{wallet.deposit.CurrencyId}</td> */}
                      {/* <td>{wallet.walletId}</td> */}
                      {/* <td>{wallet.balance}</td> */}
                      <td>${calculateTotalDeposited()}</td>
                      <td>{wallet.deposit?.depositAmount}</td>
                      <td>{wallet.deposit?.currencyName}</td>
                      <td>{wallet.deposit?.currencySymbol}</td>
                      <td>
                        {/* <WithdrawButton onClick={() => handleWithdraw(wallet)}>
                          Request Withdrawal
                        </WithdrawButton> */}
                          <WithdrawButton>
                            <NavLink 
                                to={{
                                    pathname: `/dashboard/withdrawal-request/${wallet.walletId}`,
                                    state: {
                                        walletId: wallet.walletId,
                                        userId: userId,
                                        currencyId: wallet.deposit?.currencyId,  // Ensure the correct currencyId is passed
                                    },
                                }}
                            >
                                <i className="fas fa-fw fa-wallet text-white"></i>
                                <span>Request Withdrawal</span>
                            </NavLink>
                          </WithdrawButton>


                      </td>
                    </tr>
                  </tbody>
                </Table>
                </ScrollableTableContainer>
              ))}
            </div>
          ))}

          {/* Subscriptions */}
          <h3>Investment Subscriptions</h3>
          {Object.keys(userData.subscriptions).map((subscriptionType) => (
                <div key={subscriptionType}>
                <h4>{subscriptionType.charAt(0).toUpperCase() + subscriptionType.slice(1)} Subscription</h4>
                {userData.subscriptions[subscriptionType].map((subscription) => (
                    <ScrollableTableContainer key={subscription.planId}>
                    
                    <Table key={subscription.planId}>

                    <thead>
                        <tr>
                        <th>Plan Name</th>
                        <th>Duration</th>
                        <th>Total Amount</th>
                        <th>Deposit Amount</th>
                        <th>Daily Interest Gain</th>
                        <th>Total Interest</th>
                        <th>Currency</th>
                        <th>Status</th>
                        {/* <th>Actions</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>{subscription.planName}</td>
                        <td>{subscription.duration} days</td>
                        <td>{subscription.totalAmount}</td>
                        <td>{subscription.deposit.amount}</td>
                        <td>{subscription.dailyInterestGain}</td>
                        <td>{subscription.totalInterest}</td>
                        <td>{subscription.currency.symbol} ({subscription.currency.currencyName})</td>
                        <td>{subscription.deposit.status}</td>
                        {/* <td>
                            {subscription.deposit.status === 'pending' ? (
                            <ActionButton variant="complete">
                                UnApproved Transaction
                            </ActionButton>
                            ) : (
                            <ActionButton onClick={() => handleWithdraw(subscription)} variant="withdraw">
                             Request Withdrawal
                           </ActionButton>
                            )}
                        </td> */}
                        </tr>
                    </tbody>
                    </Table>
                    </ScrollableTableContainer>
                ))}
                </div>
          ))}
        </>
      ) : (
        <p>Loading...</p>
      )}

      {/* Withdrawal Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Withdraw from Wallet</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter withdrawal amount"
                value={withdrawalAmount}
                onChange={(e) => setWithdrawalAmount(e.target.value)}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Withdraw
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default ManageWithdrawal;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Modal, Button, Form } from 'react-bootstrap';
// import styled from 'styled-components';
// import { useAuth } from './AuthContext';

// // Styled Components
// const Container = styled.div`
//   background-color: #000033;
//   min-height: 100vh;
//   padding: 20px;
//   color: #fff;

//   @media (max-width: 768px) {
//     padding: 10px;
//   }
// `;

// const Table = styled.table`
//   width: 100%;
//   margin: 20px 0;
//   border-collapse: collapse;

//   th, td {
//     border: 1px solid #fff;
//     padding: 10px;
//     text-align: left;

//     @media (max-width: 768px) {
//       padding: 8px;
//       font-size: 0.9rem;
//     }
//   }

//   th {
//     background-color: #f0e00f;
//     color: #000;
//   }
// `;

// const Header = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 10px 0;

//   @media (max-width: 768px) {
//     flex-direction: column;
//   }
// `;

// const ActionButton = styled(Button)`
//   background-color: ${props => (props.variant === 'complete' ? '#28a745' : '#f0e00f')};
//   color: #000033;
//   border: none;
//   margin-right: 10px;

//   &:hover {
//     background-color: ${props => (props.variant === 'complete' ? '#218838' : '#e0d00f')};
//   }
// `;

// const WithdrawButton = styled(Button)`
//   background-color: #f0e00f;
//   color: #000033;
//   border: none;

//   &:hover {
//     background-color: #e0d00f;
//   }
// `;

// // Main Component
// const ManageWithdrawal = () => {
//   const [userData, setUserData] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [withdrawalAmount, setWithdrawalAmount] = useState('');
//   const [selectedWallet, setSelectedWallet] = useState(null);
  
//   const { state } = useAuth();
//   const user = state?.user?.user;
//   const userId = user?.id;

//   // Fetch User Data
//   useEffect(() => {
//     fetchUserData();
//   }, []);

//   const fetchUserData = async () => {
//     try {
//       const response = await axios.get(`https://server.digitalspayout.com/api/crypto/wallet/user-wallet-subscription-details/${userId}`);

//       setUserData(response.data.data);
//     } catch (error) {
//       console.error('Error fetching user data:', error);
//     }
//   };

//   const handleWithdraw = (wallet) => {
//     setSelectedWallet(wallet);
//     setWithdrawalAmount('');
//     setShowModal(true);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const withdrawalData = {
//         userId: userId,
//         walletId: selectedWallet.walletId,
//         amount: withdrawalAmount,
//         currencyId: selectedWallet.deposit.currencyId,
//       };

//       await axios.post('http://localhost:3005/api/crypto/payout/request', withdrawalData);
//       setShowModal(false);
//       fetchUserData();
//     } catch (error) {
//       console.error('Error processing withdrawal:', error);
//     }
//   };

//   return (
//     <Container>
//       <Header>
//         <h1>Manage Withdrawals</h1>
//       </Header>

//       {userData ? (
//         <>
//           {/* User Info */}
//           <div>
//             <h3>User Information</h3>
//             <p><strong>Name:</strong> {user?.firstName} {user?.lastName}</p>
//             <p><strong>Email:</strong> {user?.email}</p>
//             <p><strong>Phone:</strong> {user?.phone}</p>
//           </div>

//           {/* Subscriptions */}
//           <h3>Investment Subscriptions</h3>
//           {Object.keys(userData.subscriptions).map((subscriptionType) => (
//             <div key={subscriptionType}>
//               <h4>{subscriptionType.charAt(0).toUpperCase() + subscriptionType.slice(1)} Subscription</h4>
//               {userData.subscriptions[subscriptionType].map((subscription) => (
//                 <Table key={subscription.planId}>
//                   <thead>
//                     <tr>
//                       <th>Plan Name</th>
//                       <th>Duration</th>
//                       <th>Total Amount</th>
//                       <th>Daily Interest Gain</th>
//                       <th>Total Interest</th>
//                       <th>Currency</th>
//                       <th>Status</th>
//                       <th>Actions</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr>
//                       <td>{subscription.planName}</td>
//                       <td>{subscription.duration} days</td>
//                       <td>{subscription.totalAmount}</td>
//                       <td>{subscription.dailyInterestGain}</td>
//                       <td>{subscription.totalInterest}</td>
//                       <td>{subscription.currency.symbol} ({subscription.currency.currencyName})</td>
//                       <td>{subscription.deposit.status}</td>
//                       <td>
//                         {subscription.deposit.status === 'pending' ? (
//                           <ActionButton onClick={() => handleWithdraw(subscription)} variant="withdraw">
//                             Request Withdrawal
//                           </ActionButton>
//                         ) : (
//                           <ActionButton variant="complete">
//                             Complete Transaction
//                           </ActionButton>
//                         )}
//                       </td>
//                     </tr>
//                   </tbody>
//                 </Table>
//               ))}
//             </div>
//           ))}
//         </>
//       ) : (
//         <p>Loading...</p>
//       )}

//       {/* Withdrawal Modal */}
//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Withdraw from Wallet</Modal.Title>
//         </Modal.Header>
//         <Form onSubmit={handleSubmit}>
//           <Modal.Body>
//             <Form.Group className="mb-3">
//               <Form.Label>Amount</Form.Label>
//               <Form.Control
//                 type="number"
//                 placeholder="Enter withdrawal amount"
//                 value={withdrawalAmount}
//                 onChange={(e) => setWithdrawalAmount(e.target.value)}
//                 required
//               />
//             </Form.Group>
//           </Modal.Body>
//           <Modal.Footer>
//             <Button variant="secondary" onClick={() => setShowModal(false)}>
//               Close
//             </Button>
//             <Button type="submit" variant="primary">
//               Withdraw
//             </Button>
//           </Modal.Footer>
//         </Form>
//       </Modal>
//     </Container>
//   );
// };

// export default ManageWithdrawal;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Modal, Button, Form } from 'react-bootstrap';
// import styled from 'styled-components';
// import { useAuth } from './AuthContext'; // Importing useAuth for authentication

// // Styled Components
// const Container = styled.div`
//   background-color: #000033;
//   min-height: 100vh;
//   padding: 20px;
//   color: #fff;

//   @media (max-width: 768px) {
//     padding: 10px;
//   }
// `;

// const Table = styled.table`
//   width: 100%;
//   margin: 20px 0;
//   border-collapse: collapse;

//   th, td {
//     border: 1px solid #fff;
//     padding: 10px;
//     text-align: left;

//     @media (max-width: 768px) {
//       padding: 8px;
//       font-size: 0.9rem;
//     }
//   }

//   th {
//     background-color: #f0e00f;
//     color: #000;
//   }
// `;

// const Header = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 10px 0;

//   @media (max-width: 768px) {
//     flex-direction: column;
//   }
// `;

// const WithdrawButton = styled(Button)`
//   background-color: #f0e00f;
//   color: #000033;
//   border: none;

//   &:hover {
//     background-color: #e0d00f;
//   }
// `;

// // Main Component
// const ManageWithdrawal = () => {
//   const [userData, setUserData] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [withdrawalAmount, setWithdrawalAmount] = useState('');
//   const [selectedWallet, setSelectedWallet] = useState(null);
  
//   // Use AuthContext to get the authenticated user
//   const { state } = useAuth();
//   const user = state?.user?.user;
//   const userId = user?.id; // Extract userId from the authenticated user

//   // Fetch User Data
//   useEffect(() => {
//     fetchUserData();
//   }, []);

//   const fetchUserData = async () => {
//     try {
//     //   const response = await axios.get('https://example.com/api/user-withdrawal-details');
//       const response = await axios.get(`https://server.digitalspayout.com/api/crypto/wallet/user-wallet-subscription-details/${userId}`);

//       setUserData(response.data.data);
//     } catch (error) {
//       console.error('Error fetching user data:', error);
//     }
//   };

//   const handleWithdraw = (wallet) => {
//     setSelectedWallet(wallet);
//     setWithdrawalAmount('');
//     setShowModal(true);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       // Make withdrawal request with the selected wallet and withdrawalAmount
//       const withdrawalData = {
//         userId: userId, // From AuthContext
//         walletId: selectedWallet.walletId,
//         amount: withdrawalAmount,
//         currencyId: selectedWallet.deposit.currencyId // Assuming currencyId is available from the deposit
//       };

//       await axios.post('http://localhost:3005/api/crypto/payout/request', withdrawalData);
//       setShowModal(false);
//       fetchUserData(); // Refresh the data after withdrawal
//     } catch (error) {
//       console.error('Error processing withdrawal:', error);
//     }
//   };

//   return (
//     <Container>
//       <Header>
//         <h1>Manage Withdrawals</h1>
//       </Header>

//       {userData ? (
//         <>
//           {/* User Info */}
//           <div>
//             <h3>User Information</h3>
//             <p><strong>Name:</strong> {user?.firstName} {user?.lastName}</p>
//             <p><strong>Email:</strong> {user?.email}</p>
//             <p><strong>Phone:</strong> {user?.phone}</p>
//           </div>

//           {/* Wallets */}
//           <h3>Wallets</h3>
//           {Object.keys(userData.wallets).map((walletType) => (
//             <div key={walletType}>
//               <h4>{walletType.charAt(0).toUpperCase() + walletType.slice(1)} Wallet</h4>
//               {userData.wallets[walletType].map((wallet) => (
//                 <Table key={wallet.walletId}>
//                   <thead>
//                     <tr>
//                       <th>Wallet Address</th>
//                       <th>Balance</th>
//                       <th>Deposit Amount</th>
//                       <th>Actions</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr>
//                       <td>{wallet.walletAddress}</td>
//                       <td>{wallet.balance}</td>
//                       <td>{wallet.deposit.depositAmount}</td>
//                       <td>
//                         <WithdrawButton onClick={() => handleWithdraw(wallet)}>Withdraw</WithdrawButton>
//                       </td>
//                     </tr>
//                   </tbody>
//                 </Table>
//               ))}
//             </div>
//           ))}

//           {/* Subscriptions */}
//           <h3>Subscriptions</h3>
//           {Object.keys(userData.subscriptions).map((subscriptionType) => (
//             <div key={subscriptionType}>
//               <h4>{subscriptionType.charAt(0).toUpperCase() + subscriptionType.slice(1)} Subscription</h4>
//               {userData.subscriptions[subscriptionType].map((subscription) => (
//                 <Table key={subscription.planId}>
//                   <thead>
//                     <tr>
//                       <th>Plan Name</th>
//                       <th>Total Amount</th>
//                       <th>Total Interest</th>
//                       <th>Currency</th>
//                       <th>Status</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr>
//                       <td>{subscription.planName}</td>
//                       <td>{subscription.totalAmount}</td>
//                       <td>{subscription.totalInterest}</td>
//                       <td>{subscription.currency.symbol} ({subscription.currency.currencyName})</td>
//                       <td>{subscription.deposit.status}</td>
//                     </tr>
//                   </tbody>
//                 </Table>
//               ))}
//             </div>
//           ))}
//         </>
//       ) : (
//         <p>Loading...</p>
//       )}

//       {/* Withdrawal Modal */}
//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Withdraw from Wallet</Modal.Title>
//         </Modal.Header>
//         <Form onSubmit={handleSubmit}>
//           <Modal.Body>
//             <Form.Group className="mb-3">
//               <Form.Label>Amount</Form.Label>
//               <Form.Control
//                 type="number"
//                 placeholder="Enter withdrawal amount"
//                 value={withdrawalAmount}
//                 onChange={(e) => setWithdrawalAmount(e.target.value)}
//                 required
//               />
//             </Form.Group>
//           </Modal.Body>
//           <Modal.Footer>
//             <Button variant="secondary" onClick={() => setShowModal(false)}>
//               Close
//             </Button>
//             <Button type="submit" variant="primary">
//               Withdraw
//             </Button>
//           </Modal.Footer>
//         </Form>
//       </Modal>
//     </Container>
//   );
// };

// export default ManageWithdrawal;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Modal, Button, Form } from 'react-bootstrap';
// import styled from 'styled-components';
// import { useAuth } from './AuthContext'; // Importing useAuth for authentication

// // Styled Components
// const Container = styled.div`
//   background-color: #000033;
//   min-height: 100vh;
//   padding: 20px;
//   color: #fff;

//   @media (max-width: 768px) {
//     padding: 10px;
//   }
// `;

// const Table = styled.table`
//   width: 100%;
//   margin: 20px 0;
//   border-collapse: collapse;

//   th, td {
//     border: 1px solid #fff;
//     padding: 10px;
//     text-align: left;

//     @media (max-width: 768px) {
//       padding: 8px;
//       font-size: 0.9rem;
//     }
//   }

//   th {
//     background-color: #f0e00f;
//     color: #000;
//   }
// `;

// const Header = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 10px 0;

//   @media (max-width: 768px) {
//     flex-direction: column;
//   }
// `;

// const WithdrawButton = styled(Button)`
//   background-color: #f0e00f;
//   color: #000033;
//   border: none;

//   &:hover {
//     background-color: #e0d00f;
//   }
// `;

// // Main Component
// const ManageWithdrawal = () => {
//   const [userData, setUserData] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [withdrawalAmount, setWithdrawalAmount] = useState('');
//   const [selectedWallet, setSelectedWallet] = useState(null);
  
//   // Use AuthContext to get the authenticated user
//   const { state } = useAuth();
//   const user = state?.user?.user;
//   const userId = user?.id; // Extract userId from the authenticated user

//   // Fetch User Data
//   useEffect(() => {
//     fetchUserData();
//   }, []);

//   const fetchUserData = async () => {
//     try {
//     //   const response = await axios.get('https://server.digitalspayout.com/api/user-withdrawal-details');
//       const response = await axios.get(`https://server.digitalspayout.com/api/crypto/wallet/user-wallet-subscription-details/${userId}`);

//       setUserData(response.data.data);
//     } catch (error) {
//       console.error('Error fetching user data:', error);
//     }
//   };

//   const handleWithdraw = (wallet) => {
//     setSelectedWallet(wallet);
//     setWithdrawalAmount('');
//     setShowModal(true);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       // Make withdrawal request with the selected wallet and withdrawalAmount
//       const withdrawalData = {
//         userId: userId, // From AuthContext
//         walletId: selectedWallet.walletId,
//         amount: withdrawalAmount,
//         currencyId: selectedWallet.deposit.currencyId // Assuming currencyId is available from the deposit
//       };

//       await axios.post('https://server.digitalspayout.com/api/crypto/payout/request', withdrawalData);
//       setShowModal(false);
//       fetchUserData(); // Refresh the data after withdrawal
//     } catch (error) {
//       console.error('Error processing withdrawal:', error);
//     }
//   };

//   return (
//     <Container>
//       <Header>
//         <h1>Manage Withdrawals</h1>
//       </Header>

//       {userData ? (
//         <>
//           {/* User Info */}
//           <div>
//             <h3>User Information</h3>
//             <p><strong>Name:</strong> {user?.firstName} {user?.lastName}</p>
//             <p><strong>Email:</strong> {user?.email}</p>
//             <p><strong>Phone:</strong> {user?.phone}</p>
//           </div>

//           {/* Wallets */}
//           <h3>Wallets</h3>
//           {Object.keys(userData.wallets).map((walletType) => (
//             <div key={walletType}>
//               <h4>{walletType.charAt(0).toUpperCase() + walletType.slice(1)} Wallet</h4>
//               {userData.wallets[walletType].map((wallet) => (
//                 <Table key={wallet.walletId}>
//                   <thead>
//                     <tr>
//                       <th>Wallet Address</th>
//                       <th>Balance</th>
//                       <th>Deposit Amount</th>
//                       <th>Actions</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr>
//                       <td>{wallet.walletAddress}</td>
//                       <td>{wallet.balance}</td>
//                       <td>{wallet.deposit.depositAmount}</td>
//                       <td>
//                         <WithdrawButton onClick={() => handleWithdraw(wallet)}>Withdraw</WithdrawButton>
//                       </td>
//                     </tr>
//                   </tbody>
//                 </Table>
//               ))}
//             </div>
//           ))}

//           {/* Subscriptions */}
//           <h3>Subscriptions</h3>
//           {Object.keys(userData.subscriptions).map((subscriptionType) => (
//             <div key={subscriptionType}>
//               <h4>{subscriptionType.charAt(0).toUpperCase() + subscriptionType.slice(1)} Subscription</h4>
//               {userData.subscriptions[subscriptionType].map((subscription) => (
//                 <Table key={subscription.planId}>
//                   <thead>
//                     <tr>
//                       <th>Plan Name</th>
//                       <th>Total Amount</th>
//                       <th>Total Interest</th>
//                       <th>Currency</th>
//                       <th>Status</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr>
//                       <td>{subscription.planName}</td>
//                       <td>{subscription.totalAmount}</td>
//                       <td>{subscription.totalInterest}</td>
//                       <td>{subscription.currency.symbol} ({subscription.currency.currencyName})</td>
//                       <td>{subscription.deposit.status}</td>
//                     </tr>
//                   </tbody>
//                 </Table>
//               ))}
//             </div>
//           ))}
//         </>
//       ) : (
//         <p>Loading...</p>
//       )}

//       {/* Withdrawal Modal */}
//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Withdraw from Wallet</Modal.Title>
//         </Modal.Header>
//         <Form onSubmit={handleSubmit}>
//           <Modal.Body>
//             <Form.Group className="mb-3">
//               <Form.Label>Amount</Form.Label>
//               <Form.Control
//                 type="number"
//                 placeholder="Enter withdrawal amount"
//                 value={withdrawalAmount}
//                 onChange={(e) => setWithdrawalAmount(e.target.value)}
//                 required
//               />
//             </Form.Group>
//           </Modal.Body>
//           <Modal.Footer>
//             <Button variant="secondary" onClick={() => setShowModal(false)}>
//               Close
//             </Button>
//             <Button type="submit" variant="primary">
//               Withdraw
//             </Button>
//           </Modal.Footer>
//         </Form>
//       </Modal>
//     </Container>
//   );
// };

// export default ManageWithdrawal;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Modal, Button, Form } from 'react-bootstrap';
// import styled from 'styled-components';

// // Styled Components
// const Container = styled.div`
//   background-color: #000033;
//   min-height: 100vh;
//   padding: 20px;
//   color: #fff;

//   @media (max-width: 768px) {
//     padding: 10px;
//   }
// `;

// const Table = styled.table`
//   width: 100%;
//   margin: 20px 0;
//   border-collapse: collapse;

//   th, td {
//     border: 1px solid #fff;
//     padding: 10px;
//     text-align: left;

//     @media (max-width: 768px) {
//       padding: 8px;
//       font-size: 0.9rem;
//     }
//   }

//   th {
//     background-color: #f0e00f;
//     color: #000;
//   }
// `;

// const Header = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 10px 0;

//   @media (max-width: 768px) {
//     flex-direction: column;
//   }
// `;

// const WithdrawButton = styled(Button)`
//   background-color: #f0e00f;
//   color: #000033;
//   border: none;

//   &:hover {
//     background-color: #e0d00f;
//   }
// `;

// // Main Component
// const ManageWithdrawal = () => {
//   const [userData, setUserData] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [withdrawalAmount, setWithdrawalAmount] = useState('');
//   const [selectedWallet, setSelectedWallet] = useState(null);

//   // Fetch User Data
//   useEffect(() => {
//     fetchUserData();
//   }, []);

//   const fetchUserData = async () => {
//     try {
//       const response = await axios.get('https://example.com/api/user-withdrawal-details');
//       setUserData(response.data.data);
//     } catch (error) {
//       console.error('Error fetching user data:', error);
//     }
//   };

//   const handleWithdraw = (wallet) => {
//     setSelectedWallet(wallet);
//     setWithdrawalAmount('');
//     setShowModal(true);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       // Make withdrawal request with the selected wallet and withdrawalAmount
//       const withdrawalData = {
//         walletId: selectedWallet.walletId,
//         amount: withdrawalAmount
//       };
//       await axios.post('https://example.com/api/withdraw', withdrawalData);
//       setShowModal(false);
//       fetchUserData(); // Refresh the data after withdrawal
//     } catch (error) {
//       console.error('Error processing withdrawal:', error);
//     }
//   };

//   return (
//     <Container>
//       <Header>
//         <h1>Manage Withdrawals</h1>
//       </Header>

//       {userData ? (
//         <>
//           {/* User Info */}
//           <div>
//             <h3>User Information</h3>
//             <p><strong>Name:</strong> {userData.user.firstName} {userData.user.lastName}</p>
//             <p><strong>Email:</strong> {userData.user.email}</p>
//             <p><strong>Phone:</strong> {userData.user.phone}</p>
//           </div>

//           {/* Wallets */}
//           <h3>Wallets</h3>
//           {Object.keys(userData.wallets).map((walletType) => (
//             <div key={walletType}>
//               <h4>{walletType.charAt(0).toUpperCase() + walletType.slice(1)} Wallet</h4>
//               {userData.wallets[walletType].map((wallet) => (
//                 <Table key={wallet.walletId}>
//                   <thead>
//                     <tr>
//                       <th>Wallet Address</th>
//                       <th>Balance</th>
//                       <th>Deposit Amount</th>
//                       <th>Actions</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr>
//                       <td>{wallet.walletAddress}</td>
//                       <td>{wallet.balance}</td>
//                       <td>{wallet.deposit.depositAmount}</td>
//                       <td>
//                         <WithdrawButton onClick={() => handleWithdraw(wallet)}>Withdraw</WithdrawButton>
//                       </td>
//                     </tr>
//                   </tbody>
//                 </Table>
//               ))}
//             </div>
//           ))}

//           {/* Subscriptions */}
//           <h3>Subscriptions</h3>
//           {Object.keys(userData.subscriptions).map((subscriptionType) => (
//             <div key={subscriptionType}>
//               <h4>{subscriptionType.charAt(0).toUpperCase() + subscriptionType.slice(1)} Subscription</h4>
//               {userData.subscriptions[subscriptionType].map((subscription) => (
//                 <Table key={subscription.planId}>
//                   <thead>
//                     <tr>
//                       <th>Plan Name</th>
//                       <th>Total Amount</th>
//                       <th>Total Interest</th>
//                       <th>Currency</th>
//                       <th>Status</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr>
//                       <td>{subscription.planName}</td>
//                       <td>{subscription.totalAmount}</td>
//                       <td>{subscription.totalInterest}</td>
//                       <td>{subscription.currency.symbol} ({subscription.currency.currencyName})</td>
//                       <td>{subscription.deposit.status}</td>
//                     </tr>
//                   </tbody>
//                 </Table>
//               ))}
//             </div>
//           ))}
//         </>
//       ) : (
//         <p>Loading...</p>
//       )}

//       {/* Withdrawal Modal */}
//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Withdraw from Wallet</Modal.Title>
//         </Modal.Header>
//         <Form onSubmit={handleSubmit}>
//           <Modal.Body>
//             <Form.Group className="mb-3">
//               <Form.Label>Amount</Form.Label>
//               <Form.Control
//                 type="number"
//                 placeholder="Enter withdrawal amount"
//                 value={withdrawalAmount}
//                 onChange={(e) => setWithdrawalAmount(e.target.value)}
//                 required
//               />
//             </Form.Group>
//           </Modal.Body>
//           <Modal.Footer>
//             <Button variant="secondary" onClick={() => setShowModal(false)}>
//               Close
//             </Button>
//             <Button type="submit" variant="primary">
//               Withdraw
//             </Button>
//           </Modal.Footer>
//         </Form>
//       </Modal>
//     </Container>
//   );
// };

// export default ManageWithdrawal;
