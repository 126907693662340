import React, { useState, useEffect } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  background-color: #000033;
  color: #fff;
  padding: 2px;
  margin-top: 0;

  @media (max-width: 576px) { /* Mobile */
    padding: 15px;
  }
`;

const Heading = styled.h4`
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 1.2rem;

  @media (min-width: 768px) { /* Tablets and above */
    font-size: 1.5rem;
  }
`;

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  @media (min-width: 768px) { /* Tablets and above */
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }
`;

const CopyButton = styled.button`
  background-color: #0056b3;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    background-color: #003a80;
  }
`;

const AddressText = styled.div`
  color: #ccc;
  font-size: 14px;
  word-wrap: break-word;

  @media (max-width: 576px) { /* Mobile */
    font-size: 12px;
    text-align: center;
  }

  @media (min-width: 768px) { /* Tablets and above */
    font-size: 16px;
    text-align: left;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
  justify-content: center;

  button {
    background-color: #ccc;
    // background-color: #007bff;
    border: none;
    color: white;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;

    &:hover {
      background-color: #f0e00f;
      // background-color: #0056b3;
    }

    &:disabled {
      background-color: green;
      cursor: not-allowed;
    }
  }

  @media (min-width: 768px) { /* Tablets and above */
    justify-content: flex-start;
  }
`;

const CollapsePanel = styled.div`
  background-color: #001f3f;
  padding: 15px;
  border-radius: 5px;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

const DashboardHeader = () => {
  const [gateways, setGateways] = useState([]);
  const [selectedGateway, setSelectedGateway] = useState(null);

  // Fetch payment gateways from API
  useEffect(() => {
    const fetchPaymentGateways = async () => {
      try {
        const response = await axios.get('https://server.digitalspayout.com/api/payment-gateways/all');
        if (response.data.success && response.data.data.gateways.length > 0) {
          setGateways(response.data.data.gateways); // Store all gateways
          setSelectedGateway(response.data.data.gateways[0]); // Default to the first gateway (e.g., Bitcoin)
        } else {
          toast.error('No payment gateway data found.', { position: 'top-right', autoClose: 3000 });
        }
      } catch (error) {
        toast.error(`Failed to fetch payment gateway data: ${error.message}`, { position: 'top-right', autoClose: 3000 });
      }
    };

    fetchPaymentGateways();
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Payment Gateway ID copied to clipboard!', {
        position: 'top-right',
        autoClose: 3000,
      });
    }).catch(() => {
      toast.error('Failed to copy!', {
        position: 'top-right',
        autoClose: 3000,
      });
    });
  };

  if (gateways.length === 0) {
    return (
      <Container>
        <Heading>Loading Payment Gateway Information...</Heading>
        <ToastContainer />
      </Container>
    );
  }

  return (
    <Container>
      {/* Buttons to switch between gateways */}
      <ButtonContainer>
        {gateways.map((gateway, index) => (
          <button
            key={gateway.id}
            onClick={() => setSelectedGateway(gateway)}
            disabled={selectedGateway?.id === gateway.id}
          >
            {gateway.name}
          </button>
        ))}
      </ButtonContainer>

      {/* Collapse panel to show gateway details */}
      {selectedGateway && (
        <CollapsePanel isVisible={true}>
          <Heading>{selectedGateway.description}</Heading>
          <AddressContainer>
            <CopyButton onClick={() => copyToClipboard(selectedGateway.currencyAddress)}>
              <CopyOutlined style={{ fontSize: '16px' }} />
               Address:
            <AddressText>{selectedGateway.currencyAddress}</AddressText>
            </CopyButton>
          </AddressContainer>
        </CollapsePanel>
      )}

      <ToastContainer />
    </Container>
  );
};

export default DashboardHeader;


// import React, { useEffect, useState } from 'react';
// import { CopyOutlined } from '@ant-design/icons';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import axios from 'axios'; // Axios for making API requests
// import styled from 'styled-components';

// // Styled Components for layout and responsiveness
// const Container = styled.div`
//   background-color: #000033;
//   color: #fff;
//   padding: 20px;
//   margin-top: 0;

//   @media (max-width: 576px) { /* Mobile */
//     padding: 15px;
//   }

//   @media (min-width: 576px) and (max-width: 768px) { /* Tablets */
//     padding: 20px;
//   }

//   @media (min-width: 768px) and (max-width: 1200px) { /* Laptops */
//     padding: 30px;
//   }
// `;

// const PaymentInfo = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 10px;
//   justify-content: space-between;
//   align-items: center;

//   @media (min-width: 768px) { /* Tablets and above */
//     flex-direction: row;
//     align-items: center;
//     gap: 20px;
//   }
// `;

// const Heading = styled.h4`
//   font-weight: bold;
//   text-align: center;
//   margin-bottom: 20px;
//   font-size: 1.2rem;

//   @media (min-width: 768px) { /* Tablets and above */
//     text-align: left;
//     font-size: 1.5rem;
//   }
// `;

// const AddressContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 10px;
//   align-items: center;

//   @media (min-width: 768px) { /* Tablets and above */
//     flex-direction: row;
//     align-items: center;
//     gap: 15px;
//   }
// `;

// const CopyButton = styled.button`
//   background-color: #0056b3;
//   color: white;
//   padding: 10px 20px;
//   border-radius: 5px;
//   border: none;
//   display: flex;
//   align-items: center;
//   gap: 8px;
//   cursor: pointer;

//   &:hover {
//     background-color: #003a80;
//   }

//   @media (max-width: 576px) { /* Mobile */
//     padding: 8px 12px;
//     font-size: 0.9rem;
//   }

//   @media (min-width: 576px) and (max-width: 768px) { /* Tablets */
//     padding: 10px 15px;
//   }
// `;

// const AddressText = styled.div`
//   color: #ccc;
//   font-size: 14px;
//   word-wrap: break-word;

//   @media (max-width: 576px) { /* Mobile */
//     font-size: 12px;
//     text-align: center;
//   }

//   @media (min-width: 768px) { /* Tablets and above */
//     font-size: 16px;
//     text-align: left;
//   }
// `;

// const LoadingMessage = styled.h4`
//   text-align: center;
//   color: #fff;

//   @media (max-width: 576px) { /* Mobile */
//     font-size: 1.2rem;
//   }

//   @media (min-width: 768px) { /* Tablets and above */
//     font-size: 1.5rem;
//   }
// `;

// const DashboardHeader = () => {
//   const [paymentGateway, setPaymentGateway] = useState(null);

//   // Fetch payment gateway data from the API when the component mounts
//   useEffect(() => {
//     const fetchPaymentGateway = async () => {
//       try {
//         const response = await axios.get('https://server.digitalspayout.com/api/payment-gateways/all');
        
//         if (response.data.success && response.data.data.gateways.length > 0) {
//           setPaymentGateway(response.data.data.gateways[0]); // Store the first gateway's details
//         } else {
//           toast.error('No payment gateway data found.', { position: 'top-right', autoClose: 3000 });
//         }
//       } catch (error) {
//         toast.error(`Failed to fetch payment gateway data: ${error.message}`, { position: 'top-right', autoClose: 3000 });
//       }
//     };

//     fetchPaymentGateway();
//   }, []);

//   const copyToClipboard = (text) => {
//     navigator.clipboard.writeText(text).then(() => {
//       toast.success('Payment Gateway ID copied to clipboard!', {
//         position: 'top-right',
//         autoClose: 3000,
//       });
//     }).catch(() => {
//       toast.error('Failed to copy!', {
//         position: 'top-right',
//         autoClose: 3000,
//       });
//     });
//   };

//   if (!paymentGateway) {
//     return (
//       <Container>
//         <LoadingMessage>Loading Payment Gateway Information...</LoadingMessage>
//         <ToastContainer />
//       </Container>
//     );
//   }

//   return (
//     <Container>
//       <PaymentInfo>
//         <div>
//           <Heading>{paymentGateway.description}</Heading>
//         </div>
//         <AddressContainer>
//           <CopyButton onClick={() => copyToClipboard(paymentGateway.currencyAddress)}>
//             <CopyOutlined style={{ fontSize: '16px' }} />
//             Copy Payment Gateway ID
//           </CopyButton>
//           <AddressText>{paymentGateway.currencyAddress}</AddressText>
//         </AddressContainer>
//       </PaymentInfo>
      
//       <ToastContainer />
//     </Container>
//   );
// };

// export default DashboardHeader;



// import React from 'react'; 
// import { CopyOutlined } from '@ant-design/icons';
// // import { toast, ToastContainer } from 'react-toastify';
// // import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for styling

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const DashboardHeader = () => {
//   // Payment gateway object
//   const paymentGateway = {
//     currencyAddress: "uhxc3c6cc9b7bebef2ed88a9febc8c7fe29",
//     name: "Our Payment Gateway",
//     description: "Use our secure payment gateway to deposit funds into your wallet:",
//   };

//   const copyToClipboard = (text) => {
//     navigator.clipboard.writeText(text).then(() => {
//       toast.success('Payment Gateway ID copied to clipboard!', {
//         position: 'top-right', // Directly using the string for position
//         autoClose: 3000,
//       });
//     }).catch(() => {
//       toast.error('Failed to copy!', {
//         position: 'top-right', // Directly using the string for position
//         autoClose: 3000,
//       });
//     });
//   };

//   return (
//     <div className="container-fluid" style={{ backgroundColor: '#000033', color: '#fff', marginTop: 0, paddingBottom: '20px' }}>
//       {/* Page Heading */}
//       <div className="row mb-4">
//         <div className="col-12 d-flex flex-column flex-md-row align-items-center justify-content-between">
//           <div className="payment-info" style={{ backgroundColor: '#000033', color: '#fff' }}>
//             <div className="mb-3">
//               <h4 style={{ marginBottom: '20px', fontWeight: 'bold' }}>
//                 {paymentGateway.description}
//               </h4>
//             </div>
//             <div className="d-flex align-items-center" style={{ gap: '10px' }}>
//               <button 
//                 onClick={() => copyToClipboard(paymentGateway.currencyAddress)} 
//                 className="btn btn-outline-light btn-sm d-flex align-items-center"
//                 style={{ backgroundColor: '#0056b3', padding: '10px 15px', borderRadius: '5px' }}
//               >
//                 <CopyOutlined style={{ fontSize: '16px', marginRight: '8px' }} /> 
//                 Copy Payment Gateway ID
//               </button>
//               <div style={{ color: '#ccc', fontSize: '14px' }}>
//                 {paymentGateway.currencyAddress}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Toast Container to display notifications */}
//       <ToastContainer />
//     </div>
//   );
// };

// export default DashboardHeader;

