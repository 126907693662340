import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { useAuth } from './AuthContext'; // Adjust the import path based on your file structure
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Styled Components
const Container = styled.div`
  background-color: #000033;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  color: #fff;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
`;

const Card = styled.div`
  background-color: #fff;
  color: #000033;
  padding: 25px;
  border-radius: 8px;
  border: 2px solid #f0e00f;
  margin: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 600px; /* Limit the card width for better responsiveness */
  width: 100%; /* Allow card to be responsive */
  
 width: 100%;
  min-height: 100vh;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  @media (max-width: 768px) {
    padding: 15px; /* Adjust padding for smaller screens */
  }

  @media (max-width: 576px) {
    padding: 10px; /* Further reduce padding for very small screens */
  }
`;

const Title = styled.h2`
  color: #000033;
  text-align: center;
  margin-bottom: 20px;
`;

const SubTitle = styled.h5`
  color: #000033;
  margin-top: 0;
`;

const Text = styled.p`
  color: #000033;
  overflow-wrap: break-word; /* Allow long words to break */
  word-wrap: break-word; /* For older browsers */
  word-break: break-word; /* For more aggressive breaking */
`;

const Strong = styled.strong`
  color: #000033;
`;

const Label = styled.label`
  color: #000033;
  margin-bottom: 5px;
  display: block;
`;

const Input = styled.input`
  margin: 10px 0;
  padding: 8px;
  width: 100%;
  background-color: #000033; /* Use specified color for input */
  color: #fff;
  border: 1px solid #f0e00f;
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: #fff;
  }
`;

const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
  width: 100%; /* Make button full width for better usability */

  &:hover {
    background-color: #0056b3;
  }
`;

const ErrorMessage = styled.p`
  color: red;
`;

const SuccessMessage = styled.p`
  color: green;
`;


const WithdrawCurrency = () => {
  const { walletId } = useParams();
  const [userData, setUserData] = useState(null);
  const [walletDetails, setWalletDetails] = useState(null);
  const [withdrawalAmount, setWithdrawalAmount] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const { state } = useAuth();
  const user = state?.user?.user;
  const userId = user?.id;

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`https://server.digitalspayout.com/api/crypto/wallet/user-wallet-subscription-details/${userId}`);
      setUserData(response.data.data);
      if (response.data.data) {
        const wallets = response.data.data.wallets;
        const selectedWallet = Object.values(wallets).flat().find(wallet => wallet.walletId === parseInt(walletId));
        setWalletDetails(selectedWallet);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      toast.error('Error fetching user data.');
    }
  };

  const handleWithdrawal = async () => {
    if (!withdrawalAmount || isNaN(withdrawalAmount) || parseFloat(withdrawalAmount) <= 0) {
      setError('Please enter a valid withdrawal amount.');
      toast.error('Please enter a valid withdrawal amount.');
      return;
    }

    const withdrawalData = {
      walletId: walletId,
      currencyId: walletDetails?.deposit?.CurrencyId,
      userId: userId,
      amount: parseFloat(withdrawalAmount),
    };

    try {
      const response = await axios.post('https://server.digitalspayout.com/api/crypto/payout/request', withdrawalData);
      if (response.data.success) {
        setSuccessMessage('Withdrawal request submitted successfully! You will get credited within 24 hours after approval');
        toast.success('Withdrawal request submitted successfully!');
        setWithdrawalAmount('');
      } else {
        setError('Failed to submit withdrawal request. Please try again.');
        toast.error('Failed to submit withdrawal request. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting withdrawal request:', error);
      toast.error('An error occurred while submitting your request.');
    }
  };

  return (
    <Container>
      <Card>
      <div style={{color:'#000033'}}>
        <Title style={{color:'#000033', font:'small-caption'}}>
        We prioritize your convenience and confidence in our platform.<br></br> 
        All withdrawal requests are processed swiftly and credited within 24 hours after approval.<br></br>
         Enjoy the peace of mind knowing your funds are just a click away!
           </Title>
      </div>
        <Title>Withdrawal Request </Title>
        {walletId ? (
          <div>
            <SubTitle>Wallet Details</SubTitle>
            {userId && userData ? (
              <div>
                {walletDetails ? (
                  <>
                    <Text>
                      <Strong>Wallet Address:</Strong> {walletDetails.walletAddress}
                    </Text>
                    <Text>
                      <Strong>Wallet Deposit Amount:</Strong> {walletDetails.balance}
                    </Text>

                    <div>
                      <Label htmlFor="withdrawalAmount">Withdrawal Amount:</Label>
                      <Input
                        type="number"
                        id="withdrawalAmount"
                        value={withdrawalAmount}
                        onChange={(e) => setWithdrawalAmount(e.target.value)}
                        placeholder="Enter amount to withdraw"
                        style={{color:'#ffffff', fontWeight:'bolder'}}
                      />
                    </div>
                    <Button onClick={handleWithdrawal}>Submit Withdrawal</Button>

                    {error && <Text style={{ color: 'red' }}>{error}</Text>}
                    
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                    {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
                  </>
                ) : (
                  <Text>No wallet details found for this ID.</Text>
                )}
              </div>
            ) : (
              <Text>Loading user data...</Text>
            )}
          </div>
        ) : (
          <Text>No wallet details available</Text>
        )}
      </Card>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover />
    </Container>
  );
};

export default WithdrawCurrency;


// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import styled from 'styled-components';
// import axios from 'axios';
// import { useAuth } from './AuthContext'; // Adjust the import path based on your file structure

// // Styled Components
// const Container = styled.div`
//   background-color: #000033;
//   width: 100%;
//   min-height: 100vh;
//   padding: 20px;
//   color: #fff;
//   display: flex;
//   justify-content: center; /* Center horizontally */
//   align-items: center; /* Center vertically */
// `;

// const Card = styled.div`
//   background-color: #fff;
//   color: #000033;
//   padding: 20px;
//   border-radius: 8px;
//   border:2px solid #f0e00f;
//   margin: 20px;
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
//   max-width: 600px; /* Limit the card width for better responsiveness */
//   width: 100%; /* Allow card to be responsive */
  
//   @media (max-width: 768px) {
//     padding: 15px; /* Adjust padding for smaller screens */
//   }

//   @media (max-width: 576px) {
//     padding: 10px; /* Further reduce padding for very small screens */
//   }
// `;

// const Title = styled.h2`
//   color: #000033;
//   text-align: center;
//   margin-bottom: 20px;
// `;

// const SubTitle = styled.h5`
//   color: #000033;
//   margin-top: 0;
// `;

// const Text = styled.p`
//   color: #000033;
//   overflow-wrap: break-word; /* Allow long words to break */
//   word-wrap: break-word; /* For older browsers */
//   word-break: break-word; /* For more aggressive breaking */
// `;

// const Strong = styled.strong`
//   color: #000033;
// `;

// const Label = styled.label`
//   color: #000033;
//   margin-bottom: 5px;
//   display: block;
// `;

// const Input = styled.input`
//   margin: 10px 0;
//   padding: 8px;
//   width: 100%;
//   background-color: #000033; /* Use specified color for input */
//   color: #fff;
//   border: 1px solid #f0e00f;
//   border-radius: 4px;

//   &:focus {
//     outline: none;
//     border-color: #fff;
//   }
// `;

// const Button = styled.button`
//   background-color: #007bff;
//   color: #fff;
//   border: none;
//   padding: 10px;
//   cursor: pointer;
//   border-radius: 4px;
//   margin-top: 10px;
//   width: 100%; /* Make button full width for better usability */

//   &:hover {
//     background-color: #0056b3;
//   }
// `;

// const ErrorMessage = styled.p`
//   color: red;
// `;

// const SuccessMessage = styled.p`
//   color: green;
// `;

// const WithdrawCurrency = () => {
//   const { walletId } = useParams();
//   const [userData, setUserData] = useState(null);
//   const [walletDetails, setWalletDetails] = useState(null);
//   const [withdrawalAmount, setWithdrawalAmount] = useState('');
//   const [error, setError] = useState('');
//   const [successMessage, setSuccessMessage] = useState('');

//   const { state } = useAuth();
//   const user = state?.user?.user;
//   const userId = user?.id;

//   useEffect(() => {
//     fetchUserData();
//   }, []);

//   const fetchUserData = async () => {
//     try {
//       const response = await axios.get(`https://server.digitalspayout.com/api/crypto/wallet/user-wallet-subscription-details/${userId}`);
//       console.log('response:', response.data.data);

//       setUserData(response.data.data);
//       if (response.data.data) {
//         const wallets = response.data.data.wallets;
//         const selectedWallet = Object.values(wallets).flat().find(wallet => wallet.walletId === parseInt(walletId));
//         setWalletDetails(selectedWallet);
//       }
//     } catch (error) {
//       console.error('Error fetching user data:', error);
//     }
//   };

//   const handleWithdrawal = async () => {
//     if (!withdrawalAmount || isNaN(withdrawalAmount) || parseFloat(withdrawalAmount) <= 0) {
//       setError('Please enter a valid withdrawal amount.');
//       return;
//     }

//     const withdrawalData = {
//       walletId: walletId,
//       currencyId: walletDetails?.deposit?.CurrencyId,
//       userId: userId,
//       amount: parseFloat(withdrawalAmount),
//     };

//     try {
//       const response = await axios.post('https://server.digitalspayout.com/api/crypto/payout/request', withdrawalData);
//       if (response.data.success) {
//         setSuccessMessage('Withdrawal request submitted successfully!');
//         setWithdrawalAmount('');
//       } else {
//         setError('Failed to submit withdrawal request. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error submitting withdrawal request:', error);
//       setError('An error occurred while submitting your request.');
//     }
//   };

//   return (
//     <Container>
//       <Card>
//         <Title>Withdrawal Request Page</Title>
//         {walletId ? (
//           <div>
//             <SubTitle>Wallet Details</SubTitle>
//             {/* <Text>
//               <Strong>Wallet ID:</Strong> {walletId}
//             </Text> */}
//             {userId && userData ? (
//               <div>
//                 {/* <Text>
//                   <Strong>User ID:</Strong> {userId}
//                 </Text> */}
//                 {walletDetails ? (
//                   <>
//                     {/* <Text>
//                       <Strong>Currency ID:</Strong> {walletDetails.deposit?.CurrencyId || 'N/A'}
//                     </Text> */}
//                     <Text>
//                       <Strong>Wallet Address:</Strong> {walletDetails.walletAddress}
//                     </Text>
//                     <Text>
//                       <Strong>Wallet Deposit Amount:</Strong> {walletDetails.balance}
//                     </Text>

//                     <div>
//                       <Label htmlFor="withdrawalAmount">Withdrawal Amount:</Label>
//                       <Input
//                         type="number"
//                         id="withdrawalAmount"
//                         value={withdrawalAmount}
//                         onChange={(e) => setWithdrawalAmount(e.target.value)}
//                         placeholder="Enter amount to withdraw"
//                         style={{color:'#ffffff', fontWeight:'bolder'}}
//                       />
//                     </div>
//                     <Button onClick={handleWithdrawal}>Submit Withdrawal</Button>

//                     {error && <ErrorMessage>{error}</ErrorMessage>}
//                     {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
//                   </>
//                 ) : (
//                   <Text>No wallet details found for this ID.</Text>
//                 )}
//               </div>
//             ) : (
//               <Text>Loading user data...</Text>
//             )}
//           </div>
//         ) : (
//           <Text>No wallet details available</Text>
//         )}
//       </Card>
//     </Container>
//   );
// };

// export default WithdrawCurrency;


// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import styled from 'styled-components';
// import axios from 'axios';
// import { useAuth } from './AuthContext'; // Adjust the import path based on your file structure

// // Styled Components
// const Container = styled.div`
//   background-color: #000033;
//   width: 100%;
//   min-height: 100vh;
//   padding: 20px;
//   color: #fff;

//   @media (max-width: 576px) {
//     padding: 10px;
//   }

//   @media only screen and (max-width: 425px) {
//     padding: 8px;
//   }

//   @media only screen and (max-width: 375px) {
//     padding: 5px;
//   }
// `;

// const Card = styled.div`
//   background-color: #fff;
//   color: #000033;
//   padding: 20px;
//   border-radius: 8px;
//   margin-top: 20px;
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
//   max-width: 600px; /* Limit the card width for better responsiveness */
//   justify-content:center;
//  width: 100%;
//   min-height: 100vh;
 
//   @media (max-width: 768px) {
//     padding: 15px; /* Adjust padding for smaller screens */
//   }
// `;

// const Title = styled.h2`
//   color: #f0e00f;
//   text-align: center;
//   margin-bottom: 20px;
// `;

// const SubTitle = styled.h5`
//   color: #000033;
//   margin-top: 0;
// `;

// const Text = styled.p`
//   color: #000033;
//   overflow-wrap: break-word; /* Allow long words to break */
//   word-wrap: break-word; /* For older browsers */
//   word-break: break-word; /* For more aggressive breaking */
// `;

// const Strong = styled.strong`
//   color: #000033;
// `;

// const Label = styled.label`
//   color: #000033;
//   margin-bottom: 5px;
//   display: block;
// `;

// const Input = styled.input`
//   margin: 10px 0;
//   padding: 8px;
//   width: 100%;
//   background-color: #f0e00f; /* Use specified color for input */
//   color: #000033;
//   border: 1px solid #000033;
//   border-radius: 4px;

//   &:focus {
//     outline: none;
//     border-color: #007bff;
//   }
// `;

// const Button = styled.button`
//   background-color: #007bff;
//   color: #fff;
//   border: none;
//   padding: 10px;
//   cursor: pointer;
//   border-radius: 4px;
//   margin-top: 10px;
//   width: 100%; /* Make button full width for better usability */

//   &:hover {
//     background-color: #0056b3;
//   }
// `;

// const ErrorMessage = styled.p`
//   color: red;
// `;

// const SuccessMessage = styled.p`
//   color: green;
// `;

// const WithdrawCurrency = () => {
//   const { walletId } = useParams();
//   const [userData, setUserData] = useState(null);
//   const [walletDetails, setWalletDetails] = useState(null);
//   const [withdrawalAmount, setWithdrawalAmount] = useState('');
//   const [error, setError] = useState('');
//   const [successMessage, setSuccessMessage] = useState('');

//   const { state } = useAuth();
//   const user = state?.user?.user;
//   const userId = user?.id;

//   useEffect(() => {
//     fetchUserData();
//   }, []);

//   const fetchUserData = async () => {
//     try {
//       const response = await axios.get(`https://server.digitalspayout.com/api/crypto/wallet/user-wallet-subscription-details/${userId}`);
//       console.log('response:', response.data.data);

//       setUserData(response.data.data);
//       if (response.data.data) {
//         const wallets = response.data.data.wallets;
//         const selectedWallet = Object.values(wallets).flat().find(wallet => wallet.walletId === parseInt(walletId));
//         setWalletDetails(selectedWallet);
//       }
//     } catch (error) {
//       console.error('Error fetching user data:', error);
//     }
//   };

//   const handleWithdrawal = async () => {
//     if (!withdrawalAmount || isNaN(withdrawalAmount) || parseFloat(withdrawalAmount) <= 0) {
//       setError('Please enter a valid withdrawal amount.');
//       return;
//     }

//     const withdrawalData = {
//       walletId: walletId,
//       currencyId: walletDetails?.deposit?.CurrencyId,
//       userId: userId,
//       amount: parseFloat(withdrawalAmount),
//     };

//     try {
//       const response = await axios.post('https://server.digitalspayout.com/api/crypto/payout/request', withdrawalData);
//       if (response.data.success) {
//         setSuccessMessage('Withdrawal request submitted successfully!');
//         setWithdrawalAmount('');
//       } else {
//         setError('Failed to submit withdrawal request. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error submitting withdrawal request:', error);
//       setError('An error occurred while submitting your request.');
//     }
//   };

//   return (
//     <Container>
//       <Title>Withdrawal Request Page</Title>
//       <Card>
//         <SubTitle>Wallet Details</SubTitle>
//         {walletId ? (
//           <div>
//             <Text>
//               <Strong>Wallet ID:</Strong> {walletId}
//             </Text>
//             {userId && userData ? (
//               <div>
//                 <Text>
//                   <Strong>User ID:</Strong> {userId}
//                 </Text>
//                 {walletDetails ? (
//                   <>
//                     <Text>
//                       <Strong>Currency ID:</Strong> {walletDetails.deposit?.CurrencyId || 'N/A'}
//                     </Text>
//                     <Text>
//                       <Strong>Wallet Address:</Strong> {walletDetails.walletAddress}
//                     </Text>
//                     <Text>
//                       <Strong>Balance:</Strong> {walletDetails.balance}
//                     </Text>

//                     <div>
//                       <Label htmlFor="withdrawalAmount">Withdrawal Amount:</Label>
//                       <Input
//                         type="number"
//                         id="withdrawalAmount"
//                         value={withdrawalAmount}
//                         onChange={(e) => setWithdrawalAmount(e.target.value)}
//                         placeholder="Enter amount to withdraw"
//                       />
//                     </div>
//                     <Button onClick={handleWithdrawal}>Submit Withdrawal</Button>

//                     {error && <ErrorMessage>{error}</ErrorMessage>}
//                     {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
//                   </>
//                 ) : (
//                   <Text>No wallet details found for this ID.</Text>
//                 )}
//               </div>
//             ) : (
//               <Text>Loading user data...</Text>
//             )}
//           </div>
//         ) : (
//           <Text>No wallet details available</Text>
//         )}
//       </Card>
//     </Container>
//   );
// };

// export default WithdrawCurrency;

// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import styled from 'styled-components';
// import axios from 'axios';
// import { useAuth } from './AuthContext'; // Adjust the import path based on your file structure

// // Styled Components
// const Container = styled.div`
//   background-color: #000033;
//   width: 100%;
//   min-height: 100vh;
//   padding: 20px;
//   color: #fff;

//   @media (max-width: 576px) {
//     padding: 10px;
//   }

//   @media only screen and (max-width: 425px) {
//     padding: 8px;
//   }

//   @media only screen and (max-width: 375px) {
//     padding: 5px;
//   }
// `;

// const Card = styled.div`
//   background-color: #fff;
//   color: #000033;
//   padding: 20px;
//   border-radius: 8px;
//   margin-top: 20px;
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
//   max-width: 600px; /* Limit the card width for better responsiveness */
//   width: 100%; /* Allow card to be responsive */

//   @media (max-width: 768px) {
//     padding: 15px; /* Adjust padding for smaller screens */
//   }
// `;

// const Title = styled.h2`
//   color: #f0e00f;
//   text-align: center;
//   margin-bottom: 20px;
// `;

// const SubTitle = styled.h5`
//   color: #000033;
//   margin-top: 0;
// `;

// const Text = styled.p`
//   color: #000033;
// `;

// const Strong = styled.strong`
//   color: #000033;
// `;

// const Label = styled.label`
//   color: #000033;
//   margin-bottom: 5px;
//   display: block;
// `;

// const Input = styled.input`
//   margin: 10px 0;
//   padding: 8px;
//   width: 100%;
//   background-color: #f0e00f; /* Use specified color for input */
//   color: #000033;
//   border: 1px solid #000033;
//   border-radius: 4px;

//   &:focus {
//     outline: none;
//     border-color: #007bff;
//   }
// `;

// const Button = styled.button`
//   background-color: #007bff;
//   color: #fff;
//   border: none;
//   padding: 10px;
//   cursor: pointer;
//   border-radius: 4px;
//   margin-top: 10px;
//   width: 100%; /* Make button full width for better usability */

//   &:hover {
//     background-color: #0056b3;
//   }
// `;

// const ErrorMessage = styled.p`
//   color: red;
// `;

// const SuccessMessage = styled.p`
//   color: green;
// `;

// const WithdrawCurrency = () => {
//   const { walletId } = useParams();
//   const [userData, setUserData] = useState(null);
//   const [walletDetails, setWalletDetails] = useState(null);
//   const [withdrawalAmount, setWithdrawalAmount] = useState('');
//   const [error, setError] = useState('');
//   const [successMessage, setSuccessMessage] = useState('');

//   const { state } = useAuth();
//   const user = state?.user?.user;
//   const userId = user?.id;

//   useEffect(() => {
//     fetchUserData();
//   }, []);

//   const fetchUserData = async () => {
//     try {
//       const response = await axios.get(`https://server.digitalspayout.com/api/crypto/wallet/user-wallet-subscription-details/${userId}`);
//       console.log('response:', response.data.data);

//       setUserData(response.data.data);
//       if (response.data.data) {
//         const wallets = response.data.data.wallets;
//         const selectedWallet = Object.values(wallets).flat().find(wallet => wallet.walletId === parseInt(walletId));
//         setWalletDetails(selectedWallet);
//       }
//     } catch (error) {
//       console.error('Error fetching user data:', error);
//     }
//   };

//   const handleWithdrawal = async () => {
//     if (!withdrawalAmount || isNaN(withdrawalAmount) || parseFloat(withdrawalAmount) <= 0) {
//       setError('Please enter a valid withdrawal amount.');
//       return;
//     }

//     const withdrawalData = {
//       walletId: walletId,
//       currencyId: walletDetails?.deposit?.CurrencyId,
//       userId: userId,
//       amount: parseFloat(withdrawalAmount),
//     };

//     try {
//       const response = await axios.post('https://server.digitalspayout.com/api/crypto/payout/request', withdrawalData);
//       if (response.data.success) {
//         setSuccessMessage('Withdrawal request submitted successfully!');
//         setWithdrawalAmount('');
//       } else {
//         setError('Failed to submit withdrawal request. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error submitting withdrawal request:', error);
//       setError('An error occurred while submitting your request.');
//     }
//   };

//   return (
//     <Container>
//       <Title>Withdrawal Request Page</Title>
//       <Card>
//         <SubTitle>Wallet Details</SubTitle>
//         {walletId ? (
//           <div>
//             <Text>
//               <Strong>Wallet ID:</Strong> {walletId}
//             </Text>
//             {userId && userData ? (
//               <div>
//                 <Text>
//                   <Strong>User ID:</Strong> {userId}
//                 </Text>
//                 {walletDetails ? (
//                   <>
//                     <Text>
//                       <Strong>Currency ID:</Strong> {walletDetails.deposit?.CurrencyId || 'N/A'}
//                     </Text>
//                     <Text>
//                       <Strong>Wallet Address:</Strong> {walletDetails.walletAddress}
//                     </Text>
//                     <Text>
//                       <Strong>Balance:</Strong> {walletDetails.balance}
//                     </Text>

//                     <div>
//                       <Label htmlFor="withdrawalAmount">Withdrawal Amount:</Label>
//                       <Input
//                         type="number"
//                         id="withdrawalAmount"
//                         value={withdrawalAmount}
//                         onChange={(e) => setWithdrawalAmount(e.target.value)}
//                         placeholder="Enter amount to withdraw"
//                       />
//                     </div>
//                     <Button onClick={handleWithdrawal}>Submit Withdrawal</Button>

//                     {error && <ErrorMessage>{error}</ErrorMessage>}
//                     {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
//                   </>
//                 ) : (
//                   <Text>No wallet details found for this ID.</Text>
//                 )}
//               </div>
//             ) : (
//               <Text>Loading user data...</Text>
//             )}
//           </div>
//         ) : (
//           <Text>No wallet details available</Text>
//         )}
//       </Card>
//     </Container>
//   );
// };

// export default WithdrawCurrency;


// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import styled from 'styled-components';
// import axios from 'axios';
// import { useAuth } from './AuthContext'; // Adjust the import path based on your file structure

// // Styled Components
// const Container = styled.div`
//   background-color: #000033;
//   width: 100%;
//   min-height: 100vh;
//   padding: 20px;
//   color: #fff;

//   @media (max-width: 576px) {
//     padding: 10px;
//   }

//   @media only screen and (max-width: 425px) {
//     padding: 8px;
//   }

//   @media only screen and (max-width: 375px) {
//     padding: 5px;
//   }
// `;

// const Card = styled.div`
//   background-color: #fff;
//   color: #000033;
//   padding: 20px;
//   border-radius: 8px;
//   margin-top: 20px;
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
// `;

// const Title = styled.h2`
//   color: #fff;
// `;

// const SubTitle = styled.h5`
//   color: #000033;
//   margin-top: 0;
// `;

// const Text = styled.p`
//   color: #000033;
// `;

// const Strong = styled.strong`
//   color: #000033;
// `;

// const Label = styled.label`
//   color: #000033;
//   margin-bottom: 5px;
//   display: block;
// `;

// const Input = styled.input`
//   margin: 10px 0;
//   padding: 8px;
//   width: 100%;
//   background-color: #ccc;
//   color: #000033;
//   border: 1px solid #000033;
//   border-radius: 4px;

//   &:focus {
//     outline: none;
//     border-color: #007bff;
//   }
// `;

// const Button = styled.button`
//   background-color: #007bff;
//   color: #fff;
//   border: none;
//   padding: 10px;
//   cursor: pointer;
//   border-radius: 4px;
//   margin-top: 10px;

//   &:hover {
//     background-color: #0056b3;
//   }
// `;

// const ErrorMessage = styled.p`
//   color: red;
// `;

// const SuccessMessage = styled.p`
//   color: green;
// `;

// const WithdrawCurrency = () => {
//   const { walletId } = useParams();
//   const [userData, setUserData] = useState(null);
//   const [walletDetails, setWalletDetails] = useState(null);
//   const [withdrawalAmount, setWithdrawalAmount] = useState('');
//   const [error, setError] = useState('');
//   const [successMessage, setSuccessMessage] = useState('');

//   const { state } = useAuth();
//   const user = state?.user?.user;
//   const userId = user?.id;

//   useEffect(() => {
//     fetchUserData();
//   }, []);

//   const fetchUserData = async () => {
//     try {
//       const response = await axios.get(`https://server.digitalspayout.com/api/crypto/wallet/user-wallet-subscription-details/${userId}`);
//       console.log('response:', response.data.data);

//       setUserData(response.data.data);
//       if (response.data.data) {
//         const wallets = response.data.data.wallets;
//         const selectedWallet = Object.values(wallets).flat().find(wallet => wallet.walletId === parseInt(walletId));
//         setWalletDetails(selectedWallet);
//       }
//     } catch (error) {
//       console.error('Error fetching user data:', error);
//     }
//   };

//   const handleWithdrawal = async () => {
//     if (!withdrawalAmount || isNaN(withdrawalAmount) || parseFloat(withdrawalAmount) <= 0) {
//       setError('Please enter a valid withdrawal amount.');
//       return;
//     }

//     const withdrawalData = {
//       walletId: walletId,
//       currencyId: walletDetails?.deposit?.CurrencyId,
//       userId: userId,
//       amount: parseFloat(withdrawalAmount),
//     };

//     try {
//       const response = await axios.post('https://server.digitalspayout.com/api/crypto/payout/request', withdrawalData);
//       if (response.data.success) {
//         setSuccessMessage('Withdrawal request submitted successfully!');
//         setWithdrawalAmount('');
//       } else {
//         setError('Failed to submit withdrawal request. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error submitting withdrawal request:', error);
//       setError('An error occurred while submitting your request.');
//     }
//   };

//   return (
//     <Container>
//       <Title>Withdrawal Request Page</Title>
//       <Card>
//         <SubTitle>Wallet Details</SubTitle>
//         {walletId ? (
//           <div>
//             <Text>
//               <Strong>Wallet ID:</Strong> {walletId}
//             </Text>
//             {userId && userData ? (
//               <div>
//                 <Text>
//                   <Strong>User ID:</Strong> {userId}
//                 </Text>
//                 {walletDetails ? (
//                   <>
//                     <Text>
//                       <Strong>Currency ID:</Strong> {walletDetails.deposit?.CurrencyId || 'N/A'}
//                     </Text>
//                     <Text>
//                       <Strong>Wallet Address:</Strong> {walletDetails.walletAddress}
//                     </Text>
//                     <Text>
//                       <Strong>Balance:</Strong> {walletDetails.balance}
//                     </Text>

//                     <div>
//                       <Label htmlFor="withdrawalAmount">Withdrawal Amount:</Label>
//                       <Input
//                         type="number"
//                         id="withdrawalAmount"
//                         value={withdrawalAmount}
//                         onChange={(e) => setWithdrawalAmount(e.target.value)}
//                         placeholder="Enter amount to withdraw"
//                       />
//                     </div>
//                     <Button onClick={handleWithdrawal}>Submit Withdrawal</Button>

//                     {error && <ErrorMessage>{error}</ErrorMessage>}
//                     {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
//                   </>
//                 ) : (
//                   <Text>No wallet details found for this ID.</Text>
//                 )}
//               </div>
//             ) : (
//               <Text>Loading user data...</Text>
//             )}
//           </div>
//         ) : (
//           <Text>No wallet details available</Text>
//         )}
//       </Card>
//     </Container>
//   );
// };

// export default WithdrawCurrency;
