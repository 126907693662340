import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Dashboard from './Dashboard';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';

const App = () => {
  const dashboardBasePath = "/dashboard";

  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<SignIn />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />

          {/* Private Route for Dashboard */}
          <Route
            path={`${dashboardBasePath}/*`}
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;


// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import SignIn from './SignIn';
// import SignUp from './SignUp';
// import Dashboard from './Dashboard';
// import { AuthProvider } from './AuthContext';
// import PrivateRoute from './PrivateRoute';

// const App = () => {
//   const dashboardBasePath = "/dashboard";

//   return (
//     <AuthProvider>
//       <Router>
//         <Routes>
//           <Route path="/" element={<SignIn />} />
//           <Route path="/login" element={<SignIn />} />
//           <Route path="/signup" element={<SignUp />} />

//           <Route
//             path={`${dashboardBasePath}/*`}
//             element={<PrivateRoute element={<Dashboard />} />}
//           />

//           {/* Dashboard Routes */}
//           <Route path={`${dashboardBasePath}/*`} element={<PrivateRoute element={<Dashboard />} />} />


  
//         </Routes>
//       </Router>

//     </AuthProvider>
//   );
// };

// export default App;


