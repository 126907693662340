import React, { useState, useEffect } from 'react';
import { Table, Button, Alert, Spinner, Modal, Form, Pagination } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import './ManageTestimonial.css';

const ManageTestimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // State for modals
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [currentTestimonial, setCurrentTestimonial] = useState(null);

  // State for form fields including the image
  const [formData, setFormData] = useState({
    name: '',
    amount: '',
    message: '',
    imagePath: null, // Use File object
    status: 'draft',
  });

  // State for image preview
  const [imagePreview, setImagePreview] = useState(null);

  // Fetch all testimonials with pagination and filtering
  const fetchTestimonials = async (page = 1, search = '') => {
    try {
      const response = await axios.get('https://server.digitalspayout.com/api/testimonial/viewAll', {
        params: { page, search }
      });
      if (response.data.success) {
        // setTestimonials(response.data.testimonials.testimonials); // Adjusted to access testimonials array
        // setTotalPages(response.data.testimonials.totalPages); // Ensure your API returns totalPages
        setTestimonials(response.data.testimonials.testimonials);
        setTotalPages(response.data.testimonials.totalPages);
        setCurrentPage(response.data.testimonials.page);
        setLoading(false);
      }
    } catch (err) {
      setError('Failed to fetch testimonials');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTestimonials(currentPage, searchTerm);
  }, [currentPage, searchTerm]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevState => ({ ...prevState, [id]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData(prevState => ({ ...prevState, imagePath: file }));
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleShowCreateModal = () => {
    setFormData({
      name: '',
      amount: '',
      message: '',
      imagePath: null,
      status: 'draft',
    });
    setImagePreview(null);
    setShowCreateModal(true);
  };

  const handleShowUpdateModal = (testimonial) => {
    setCurrentTestimonial(testimonial);
    setFormData({
      name: testimonial.name,
      amount: testimonial.amount,
      message: testimonial.message,
      status: testimonial.status,
      imagePath: null,
    });
    setImagePreview(testimonial.imagePath ? `https://server.digitalspayout.com/${testimonial.imagePath}` : null);
    setShowUpdateModal(true);
  };

  const handleCreateTestimonial = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.name);
      formDataToSend.append('amount', formData.amount);
      formDataToSend.append('message', formData.message);
      formDataToSend.append('status', formData.status);
      if (formData.imagePath) {
        formDataToSend.append('imagePath', formData.imagePath);
      }
      await axios.post('https://server.digitalspayout.com/api/testimonial/create', formDataToSend, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      Swal.fire('Success', 'Testimonial created successfully!', 'success');
      handleCloseCreateModal();
      fetchTestimonials(currentPage, searchTerm);
    } catch (error) {
      Swal.fire('Error', 'Failed to create testimonial', 'error');
    }
  };

  const handleUpdateTestimonial = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.name);
      formDataToSend.append('amount', formData.amount);
      formDataToSend.append('message', formData.message);
      formDataToSend.append('status', formData.status);
      if (formData.imagePath) {
        formDataToSend.append('imagePath', formData.imagePath);
      }
      await axios.put(`https://server.digitalspayout.com/api/testimonial/update/${currentTestimonial.id}`, formDataToSend, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      Swal.fire('Success', 'Testimonial updated successfully!', 'success');
      handleCloseUpdateModal();
      fetchTestimonials(currentPage, searchTerm);
    } catch (error) {
      Swal.fire('Error', 'Failed to update testimonial', 'error');
    }
  };

  const deleteTestimonial = async (id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(`https://server.digitalspayout.com/api/testimonial/delete/${id}`);
        Swal.fire('Deleted!', 'Testimonial has been deleted.', 'success');
        fetchTestimonials(currentPage, searchTerm);
      } catch (error) {
        Swal.fire('Error', 'Failed to delete testimonial', 'error');
      }
    }
  };

  const changeStatus = async (id) => {
    const { value: status } = await Swal.fire({
      title: 'Change Status',
      input: 'select',
      inputOptions: {
        draft: 'Draft',
        published: 'Published'
      },
      inputPlaceholder: 'Select status',
      showCancelButton: true,
    });

    if (status) {
      try {
        await axios.patch(`https://server.digitalspayout.com/api/testimonial/changeStatus/${id}`, { status });
        Swal.fire('Success', 'Status updated successfully!', 'success');
        fetchTestimonials(currentPage, searchTerm);
      } catch (error) {
        Swal.fire('Error', 'Failed to change status', 'error');
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCloseCreateModal = () => setShowCreateModal(false);
  const handleCloseUpdateModal = () => setShowUpdateModal(false);

  const truncateMessage = (message, maxLength) => {
    return message.length > maxLength ? message.substring(0, maxLength) + '...' : message;
  };

  const handleShowMessageModal = (message) => {
    Swal.fire({
      title: 'Message',
      text: message,
      confirmButtonText: 'Close'
    });
  };

  const renderImage = (imagePath) => {
    return imagePath ? <img src={`https://server.digitalspayout.com/${imagePath}`} alt="testimonial" width="50" /> : 'No Image';
  };

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <div className="container-fluid">
      <div className="button-container col-sm-4">
        <Button onClick={handleShowCreateModal} className="mb-3 btn-custom btn-sm">Create New Testimonial</Button>
      </div>
      <div className="mb-3">
        <Form.Control
          type="text"
          placeholder="Search by name"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <Table striped bordered hover responsive className="table-custom">
        <thead style={{ border: '2px solid #f0e00f', backgroundColor: '#000033', color: '#FFF' }}>
          <tr style={{color:'#000033', backgroundColor:'#f0e00f'}}>
            <th>ID</th>
            <th>Name</th>
            <th>Amount</th>
            <th>Message</th>
            <th>Status</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {Array.isArray(testimonials) && testimonials.length > 0 ? (
      
          testimonials.map((testimonial) => (
            <tr key={testimonial.id}>
              <td>{testimonial.id}</td>
              <td>{testimonial.name}</td>
              <td>{testimonial.amount}</td>
              <td>
                {truncateMessage(testimonial.message, 20)}
                <Button
                  variant="link"
                  onClick={() => handleShowMessageModal(testimonial.message)}
                  style={{ marginLeft: '10px' }}
                >
                  Read More
                </Button>
              </td>
              <td>{testimonial.status}</td>
              <td>{renderImage(testimonial.imagePath)}</td>
              <td className="actions-cell">
              <div className="btn-group btn-group-xs">
                <Button
                  variant="info"
                  size="sm"
                  onClick={() => handleShowUpdateModal(testimonial)}
                  className="me-2"
                >
                  Edit
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => deleteTestimonial(testimonial.id)}
                  className="me-2"
                >
                  Delete
                </Button>
                <Button
                  variant="warning"
                  size="sm"
                  onClick={() => changeStatus(testimonial.id)}
                >
                  Change Status
                </Button>
                </div>
              </td>
            </tr>
           ))
    ) : (
      <p>No testimonials available</p>
    )}
        </tbody>
      </Table>
      <Pagination>
        {[...Array(totalPages)].map((_, idx) => (
          <Pagination.Item
            key={idx + 1}
            active={idx + 1 === currentPage}
            onClick={() => handlePageChange(idx + 1)}
          >
            {idx + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      {/* Create Testimonial Modal */}
      <Modal show={showCreateModal} onHide={handleCloseCreateModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Testimonial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="amount" className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                value={formData.amount}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="message" className="mb-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={formData.message}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="status" className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={formData.status}
                onChange={handleInputChange}
              >
                <option value="draft">Draft</option>
                <option value="published">Published</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="imagePath" className="mb-3">
              <Form.Label>Image</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
              {imagePreview && (
                <div className="mt-3">
                  <img src={imagePreview} alt="Preview" width="100" />
                </div>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateTestimonial}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Update Testimonial Modal */}
      <Modal show={showUpdateModal} onHide={handleCloseUpdateModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Testimonial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="amount" className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                value={formData.amount}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="message" className="mb-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={formData.message}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="status" className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={formData.status}
                onChange={handleInputChange}
              >
                <option value="draft">Draft</option>
                <option value="published">Published</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="imagePath" className="mb-3">
              <Form.Label>Image</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
              {imagePreview && (
                <div className="mt-3">
                  <img src={imagePreview} alt="Preview" width="100" />
                </div>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateTestimonial}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ManageTestimonials;

