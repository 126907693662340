import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  background-color: #000033;
  min-height: 100vh;
  padding: 20px;
  color: #fff;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Table = styled.table`
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;

  th, td {
    border: 1px solid #fff;
    padding: 10px;
    text-align: left;

    @media (max-width: 768px) {
      padding: 8px;
      font-size: 0.9rem;
    }
  }

  th {
    background-color: #f0e00f;
    color: #000;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const CreateButton = styled(Button)`
  background-color: #f0e00f;
  color: #000033;
  border: none;

  &:hover {
    background-color: #e0d00f;
  }
`;

// Main Component
const ManagePaymentGateway = () => {
  const [gateways, setGateways] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentGateway, setCurrentGateway] = useState(null);
  const [formData, setFormData] = useState({
    currencyAddress: '',
    name: '',
    description: ''
  });

  // Fetch Payment Gateways
  useEffect(() => {
    fetchPaymentGateways();
  }, []);

  const fetchPaymentGateways = async () => {
    try {
      const response = await axios.get('https://server.digitalspayout.com/api/payment-gateways/all');
      setGateways(response.data.data.gateways);
    } catch (error) {
      console.error('Error fetching payment gateways:', error);
    }
  };

  const handleCreate = () => {
    setFormData({
      currencyAddress: '',
      name: '',
      description: ''
    });
    setIsUpdating(false);
    setShowModal(true);
  };

  const handleEdit = async (id) => {
    try {
      const response = await axios.get(`https://server.digitalspayout.com/api/payment-gateways/view/${id}`);
      const gateway = response.data.data.gateway;
      setCurrentGateway(gateway);
      setFormData({
        currencyAddress: gateway.currencyAddress,
        name: gateway.name,
        description: gateway.description
      });
      setIsUpdating(true);
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching gateway:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://server.digitalspayout.com/api/payment-gateways/delete/${id}`);
      fetchPaymentGateways(); // Refresh after deletion
    } catch (error) {
      console.error('Error deleting payment gateway:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (isUpdating) {
        await axios.put(`https://server.digitalspayout.com/api/payment-gateways/update/${currentGateway.id}`, formData);
      } else {
        await axios.post('https://server.digitalspayout.com/api/payment-gateways/create', formData);
      }
      setShowModal(false);
      fetchPaymentGateways();
    } catch (error) {
      console.error('Error saving payment gateway:', error);
    }
  };

  return (
    <Container>
      <Header>
        <h1>Payment Gateway Manager</h1>
        <CreateButton onClick={handleCreate}>Create New Gateway</CreateButton>
      </Header>

      {/* Payment Gateways Table */}
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Currency Address</th>
            <th>Name</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {gateways.map((gateway) => (
            <tr key={gateway.id}>
              <td>{gateway.id}</td>
              <td>{gateway.currencyAddress}</td>
              <td>{gateway.name}</td>
              <td>{gateway.description}</td>
              <td>
                <Button
                  variant="warning"
                  onClick={() => handleEdit(gateway.id)}
                  className="me-2"
                >
                  Edit
                </Button>
                <Button variant="danger" onClick={() => handleDelete(gateway.id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Create/Update Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{isUpdating ? 'Update Gateway' : 'Create Gateway'}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Currency Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter currency address"
                value={formData.currencyAddress}
                onChange={(e) =>
                  setFormData({ ...formData, currencyAddress: e.target.value })
                }
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter gateway name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter description"
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              {isUpdating ? 'Update Gateway' : 'Create Gateway'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default ManagePaymentGateway;
