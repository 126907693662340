import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useAuth } from './AuthContext'; // Assuming you have an AuthContext for user info

// Styled components for responsiveness
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000033;
  color: #fff;
  width: 100%;
  min-height: 100vh;
`;

const CardHolder = styled.div`
  background-color: #000033;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 1200px;
  width: 100%;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 26px;
  text-align: center;
  margin-bottom: 20px;
`;

const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
   background-color: #000033;
  color: #fff;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .filter-group {
    flex: 1;
    margin-right: 10px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #fff;
  }

  th {
    background-color: #000033;
    color: #fff;
  }

  td {
    color: #fff;
  }

  tbody tr:nth-child(even) {
    background-color: #333366;
  }
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  
  &:hover {
    background-color: #0056b3;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  
  button {
    margin: 0 5px;
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    
    &:hover {
      background-color: #0056b3;
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
`;

const Transactions = () => {
  const { state } = useAuth();
  const userId = state?.user?.user?.id;  // Assuming this is how you get the user ID

  const [transactions, setTransactions] = useState([]);
  const [filter, setFilter] = useState({
    type: 'all', // 'all', 'deposit', 'withdrawal', 'earning'
  });

  const [loading, setLoading] = useState(true);  // For loading state
  const [error, setError] = useState(null);  // For error handling

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage] = useState(10);  // Set the number of transactions per page

  // Fetch transactions from the API
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(`https://server.digitalspayout.com/api/crypto/user-transactions/${userId}`);
        if (response.data.success) {
          setTransactions(response.data.transactions);
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        console.error('Error fetching transactions:', err);
        setError('Failed to fetch transactions');
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchTransactions();
    }
  }, [userId]);

  const handleFilterChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const filteredTransactions = transactions.filter((transaction) => {
    const { type } = filter;

    // Filter by transaction type
    if (type !== 'all' && transaction.type !== type) return false;

    return true;
  });

  // Pagination logic
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = filteredTransactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  const totalPages = Math.ceil(filteredTransactions.length / transactionsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Container>
      <CardHolder>
        <Title>Transactions History:</Title>
        {/* Filter Section */}
        <FilterSection >
          <div className="filter-group col-4" style={{backgroundColor:'#f0f0f0', color:'#000033', borderRadius:5}}>
            <label htmlFor="type" className='text-center'>Select Transaction Type</label>
            <select id="type" name="type" value={filter.type} onChange={handleFilterChange} style={{backgroundColor:'#000033', color:'#fff', border:'2px solid #f0e00f', borderRadius:25}}>
              <option value="all">All</option>
              <option value="deposit">Deposit</option>
              <option value="withdrawal">Withdrawal</option>
              <option value="earning">Earning</option>
            </select>
          </div>
        </FilterSection>

        {/* Transactions Table */}
        <TableWrapper>
          <StyledTable>
            <thead>
              <tr>
                <th>Amount</th>
                <th>Currency</th>
                <th>Symbol</th>
                <th>Wallet Address</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5" className="text-center">Loading...</td>
                </tr>
              ) : error ? (
                <tr>
                  <td colSpan="5" className="text-center text-danger">{error}</td>
                </tr>
              ) : currentTransactions.length > 0 ? (
                currentTransactions.map((transaction, index) => (
                  <tr key={index}>
                    <td>${transaction.amount.toFixed(2)}</td>
                    <td>{transaction.currencyName}</td>
                    <td>{transaction.currencySymbol}</td>
                    <td>{transaction.walletAddress}</td>
                    <td>{transaction.type.charAt(0).toUpperCase() + transaction.type.slice(1)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center text-muted">No transactions found</td>
                </tr>
              )}
            </tbody>
          </StyledTable>
        </TableWrapper>

        {/* Pagination Controls */}
        <Pagination>
          <button onClick={prevPage} disabled={currentPage === 1}>
            Previous
          </button>
          <span>{currentPage} of {totalPages}</span>
          <button onClick={nextPage} disabled={currentPage === totalPages}>
            Next
          </button>
        </Pagination>
      </CardHolder>
    </Container>
  );
};

export default Transactions;


// import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
// import axios from 'axios';
// import { useAuth } from './AuthContext';  // Assuming you have an AuthContext for user info

// // Styled components for responsiveness
// const Container = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #000033;
//   color: #fff;
//   width: 100%;
//   min-height: 100vh;
// `;

// const CardHolder = styled.div`
//   background-color: #000033;
//   color: #fff;
//   padding: 20px;
//   border-radius: 8px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//   max-width: 1200px;
//   width: 100%;
// `;

// const Title = styled.h2`
//   font-weight: bold;
//   font-size: 26px;
//   text-align: center;
//   margin-bottom: 20px;
// `;

// const FilterSection = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   margin-bottom: 20px;
  
//   @media (max-width: 768px) {
//     flex-direction: column;
//   }

//   .filter-group {
//     flex: 1;
//     margin-right: 10px;
//     margin-bottom: 10px;

//     @media (max-width: 768px) {
//       margin-right: 0;
//     }
//   }
// `;

// const TableWrapper = styled.div`
//   overflow-x: auto;
// `;

// const StyledTable = styled.table`
//   width: 100%;
//   border-collapse: collapse;
//   border-spacing: 0;

//   th, td {
//     padding: 10px;
//     text-align: left;
//     border-bottom: 1px solid #fff;
//   }

//   th {
//     background-color: #000033;
//     color: #fff;
//   }

//   td {
//     color: #fff;
//   }

//   tbody tr:nth-child(even) {
//     background-color: #333366;
//   }
// `;

// const Button = styled.button`
//   background-color: #007bff;
//   color: white;
//   padding: 10px;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   width: 100%;
//   font-size: 16px;
  
//   &:hover {
//     background-color: #0056b3;
//   }
// `;

// const Transactions = () => {
//   const { state } = useAuth();
//   const userId = state?.user?.user?.id;  // Assuming this is how you get the user ID

//   const [transactions, setTransactions] = useState([]);
//   const [filter, setFilter] = useState({
//     type: 'all', // 'all', 'deposit', 'withdrawal', 'investment'
//     startDate: '',
//     endDate: '',
//   });

//   const [loading, setLoading] = useState(true);  // For loading state
//   const [error, setError] = useState(null);  // For error handling

//   // Fetch transactions from the API
//   useEffect(() => {
//     const fetchTransactions = async () => {
//       try {
//         const response = await axios.get(`https://server.digitalspayout.com/api/crypto/user-transactions/${userId}`);
//         if (response.data.success) {
//           setTransactions(response.data.transactions);
//         } else {
//           setError(response.data.message);
//         }
//       } catch (err) {
//         console.error('Error fetching transactions:', err);
//         setError('Failed to fetch transactions');
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (userId) {
//       fetchTransactions();
//     }
//   }, [userId]);

//   const handleFilterChange = (e) => {
//     setFilter({
//       ...filter,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const filteredTransactions = transactions.filter((transaction) => {
//     const { type, startDate, endDate } = filter;
//     const transactionDate = new Date(transaction.date);

//     // Filter by transaction type
//     if (type !== 'all' && transaction.type !== type) return false;

//     // Filter by date range
//     if (startDate && transactionDate < new Date(startDate)) return false;
//     if (endDate && transactionDate > new Date(endDate)) return false;

//     return true;
//   });

//   return (
//     <Container>
//       <CardHolder>
//         <Title>Transactions History:</Title>
//         {/* Filter Section */}
//         <FilterSection>
//           <div className="filter-group">
//             <label htmlFor="type">Transaction Type</label>
//             <select id="type" name="type" value={filter.type} onChange={handleFilterChange}>
//               <option value="all">All</option>
//               <option value="deposit">Deposit</option>
//               <option value="withdrawal">Withdrawal</option>
//               <option value="investment">Investment</option>
//             </select>
//           </div>

//           <div className="filter-group">
//             <label htmlFor="startDate">Start Date</label>
//             <input type="date" id="startDate" name="startDate" value={filter.startDate} onChange={handleFilterChange} />
//           </div>

//           <div className="filter-group">
//             <label htmlFor="endDate">End Date</label>
//             <input type="date" id="endDate" name="endDate" value={filter.endDate} onChange={handleFilterChange} />
//           </div>

//           <div className="filter-group">
//             <Button>Apply Filters</Button>
//           </div>
//         </FilterSection>

//         {/* Transactions Table */}
//         <TableWrapper>
//           <StyledTable>
//             <thead>
//               <tr>
//                 <th>Amount</th>
//                 <th>Currency</th>
//                 <th>Symbol</th>
//                 <th>Wallet Address</th>
//                 <th>Type</th>
//               </tr>
//             </thead>
//             <tbody>
//               {loading ? (
//                 <tr>
//                   <td colSpan="5" className="text-center">Loading...</td>
//                 </tr>
//               ) : error ? (
//                 <tr>
//                   <td colSpan="5" className="text-center text-danger">{error}</td>
//                 </tr>
//               ) : filteredTransactions.length > 0 ? (
//                 filteredTransactions.map((transaction, index) => (
//                   <tr key={index}>
//                     <td>${transaction.amount.toFixed(2)}</td>
//                     <td>{transaction.currencyName}</td>
//                     <td>{transaction.currencySymbol}</td>
//                     <td>{transaction.walletAddress}</td>
//                     <td>{transaction.type.charAt(0).toUpperCase() + transaction.type.slice(1)}</td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="5" className="text-center text-muted">No transactions found</td>
//                 </tr>
//               )}
//             </tbody>
//           </StyledTable>
//         </TableWrapper>
//       </CardHolder>
//     </Container>
//   );
// };

// export default Transactions;


// import React, { useState, useEffect } from 'react';
// import { Table, Form, Button, Row, Col } from 'react-bootstrap';
// import axios from 'axios';
// import { useAuth } from './AuthContext';  // Assuming you have an AuthContext for user info

// const Transactions = () => {
//   const { state } = useAuth();
//   const userId = state?.user?.user?.id;  // Assuming this is how you get the user ID

//   const [transactions, setTransactions] = useState([]);
//   const [filter, setFilter] = useState({
//     type: 'all', // 'all', 'deposit', 'withdrawal', 'investment'
//     startDate: '',
//     endDate: '',
//   });

//   const [loading, setLoading] = useState(true);  // For loading state
//   const [error, setError] = useState(null);  // For error handling

//   // Fetch transactions from the API
//   useEffect(() => {
//     const fetchTransactions = async () => {
//       try {
//         const response = await axios.get(`https://server.digitalspayout.com/api/crypto/user-transactions/${userId}`);
//         if (response.data.success) {
//           setTransactions(response.data.transactions);
//         } else {
//           setError(response.data.message);
//         }
//       } catch (err) {
//         console.error('Error fetching transactions:', err);
//         setError('Failed to fetch transactions');
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (userId) {
//       fetchTransactions();
//     }
//   }, [userId]);

//   const handleFilterChange = (e) => {
//     setFilter({
//       ...filter,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const filteredTransactions = transactions.filter((transaction) => {
//     const { type, startDate, endDate } = filter;
//     const transactionDate = new Date(transaction.date);

//     // Filter by transaction type
//     if (type !== 'all' && transaction.type !== type) return false;

//     // Filter by date range
//     if (startDate && transactionDate < new Date(startDate)) return false;
//     if (endDate && transactionDate > new Date(endDate)) return false;

//     return true;
//   });

//   return (
//     <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center" style={{ backgroundColor:'#000033', color:'#fff' }}>
//       <div className="card-holder card shadow-sm p-4 mb-5 rounded " style={{ backgroundColor:'#000033', color:'#fff' }}>
//       <div className="justify-content-center mb-2" style={{backgroundColor:'#000033', color:'#fff' }}>
//           <span className="text-bold" style={{ fontWeight:'bolder', fontSize:'26px'}}>Transactions History:</span>
//         </div>
//         <div className="transactions-widget p-4 bg-white rounded shadow-sm" style={{ backgroundColor:'#000033', color:'#fff' }}>
//           {/* Filter Section */}
//           <div className="filter-section mb-4" style={{  backgroundColor:'#000033', color:'#fff' }}>
//             <Row className="align-items-end">
//               <Col md={3} sm={6} className="mb-3">
//                 <Form.Group controlId="type">
//                   <Form.Label>Transaction Type</Form.Label>
//                   <Form.Control as="select" name="type" value={filter.type} onChange={handleFilterChange}>
//                     <option value="all">All</option>
//                     <option value="deposit">Deposit</option>
//                     <option value="withdrawal">Withdrawal</option>
//                     <option value="investment">Investment</option>
//                   </Form.Control>
//                 </Form.Group>
//               </Col>

//               <Col md={3} sm={6} className="mb-3">
//                 <Form.Group controlId="startDate">
//                   <Form.Label>Start Date</Form.Label>
//                   <Form.Control type="date" name="startDate" value={filter.startDate} onChange={handleFilterChange} />
//                 </Form.Group>
//               </Col>

//               <Col md={3} sm={6} className="mb-3">
//                 <Form.Group controlId="endDate">
//                   <Form.Label>End Date</Form.Label>
//                   <Form.Control type="date" name="endDate" value={filter.endDate} onChange={handleFilterChange} />
//                 </Form.Group>
//               </Col>

//               <Col md={3} sm={6} className="text-md-end">
//                 <Button variant="primary" className="w-100">
//                   Apply Filters
//                 </Button>
//               </Col>
//             </Row>
//           </div>

//           {/* Transactions Table */}
//           <div className="table-responsive">
//             <Table striped bordered hover className="mb-0">
//               <thead className="table-dark" style={{backgroundColor:'#000033', color:'#fff' }}>
//                 <tr  style={{ backgroundColor:'#000033', color:'#fff' }}>
//                   <th style={{ backgroundColor:'#000033', color:'#fff' }}>Amount</th>
//                   <th style={{ backgroundColor:'#000033', color:'#fff' }}>Currency</th>
//                   <th style={{ backgroundColor:'#000033', color:'#fff' }}>Symbol</th>
//                   <th style={{ backgroundColor:'#000033', color:'#fff' }}>Wallet Address</th>
//                   <th style={{ backgroundColor:'#000033', color:'#fff' }}>Type</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {loading ? (
//                   <tr>
//                     <td colSpan="5" className="text-center">Loading...</td>
//                   </tr>
//                 ) : error ? (
//                   <tr>
//                     <td colSpan="5" className="text-center text-danger">{error}</td>
//                   </tr>
//                 ) : filteredTransactions.length > 0 ? (
//                   filteredTransactions.map((transaction, index) => (
//                     <tr key={index} style={{ backgroundColor:'#000033', color:'#fff' }}>
//                       <td>${transaction.amount.toFixed(2)}</td>
//                       <td>{transaction.currencyName}</td>
//                       <td>{transaction.currencySymbol}</td>
//                       <td>{transaction.walletAddress}</td>
//                       <td>{transaction.type.charAt(0).toUpperCase() + transaction.type.slice(1)}</td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="5" className="text-center text-muted">No transactions found</td>
//                   </tr>
//                 )}
//               </tbody>
//             </Table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Transactions;
