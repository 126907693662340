// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import Swal from 'sweetalert2';
// // import dayjs from 'dayjs';
// // import styled from 'styled-components';

// // // Styled Components
// // const Container = styled.div`
// //   background-color: #000033;
// //   width: 100%;
// //   min-height: 100vh;
// //   padding: 20px;
// //   color: #fff;

// //   @media (max-width: 576px) {
// //     padding: 10px;
// //   }

// //   @media only screen and (max-width: 425px) {
// //     padding: 8px;
// //   }

// //   @media only screen and (max-width: 375px) {
// //     padding: 5px;
// //   }
// // `;

// // const ScrollableTableContainer = styled.div`
// //   width: 100%;
// //   overflow-x: auto; /* Horizontal scroll for smaller screens */
// //   margin-top: 20px;

// //   @media (max-width: 576px) {
// //     margin-top: 15px;
// //   }

// //   @media (max-width: 425px) {
// //     margin-top: 10px;
// //   }

// //   @media (max-width: 375px) {
// //     margin-top: 8px;
// //   }
// // `;

// // const Table = styled.table`
// //   width: 100%;
// //   min-width: 600px; /* Minimum width for better scrolling */
// //   border-collapse: collapse;
// //   font-size: 1rem;
// //   margin-top: 20px;

// //   th, td {
// //     border: 1px solid #fff;
// //     padding: 12px;
// //     text-align: left;
// //   }

// //   th {
// //     background-color: #f0e00f;
// //     color: #000;
// //   }

// //   @media (max-width: 992px) {
// //     font-size: 0.9rem;
// //   }

// //   @media (max-width: 576px) {
// //     font-size: 0.8rem;
// //     padding: 8px;
// //   }

// //   @media only screen and (max-width: 425px) {
// //     font-size: 0.75rem;
// //     padding: 6px;
// //   }

// //   @media only screen and (max-width: 375px) {
// //     font-size: 0.7rem;
// //     padding: 5px;
// //   }
// // `;

// // const Header = styled.div`
// //   display: flex;
// //   justify-content: space-between;
// //   align-items: center;
// //   padding: 10px 0;
// //   flex-wrap: wrap;

// //   h1 {
// //     font-size: 2rem;
// //   }

// //   @media (max-width: 768px) {
// //     h1 {
// //       font-size: 1.5rem;
// //     }
// //   }

// //   @media (max-width: 576px) {
// //     flex-direction: column;
// //     align-items: flex-start;

// //     h1 {
// //       font-size: 1.3rem;
// //     }
// //   }
// // `;

// // const Button = styled.button`
// //   font-size: 0.9rem;
// //   padding: 6px 12px;
// //   margin: 5px;
// //   background-color: ${props => (props.variant === 'complete' ? '#28a745' : '#f0e00f')};
// //   color: #000033;
// //   border: none;
// //   cursor: pointer;

// //   &:hover {
// //     background-color: ${props => (props.variant === 'complete' ? '#218838' : '#e0d00f')};
// //   }

// //   @media (max-width: 768px) {
// //     font-size: 0.8rem;
// //     padding: 5px 10px;
// //   }

// //   @media (max-width: 576px) {
// //     font-size: 0.75rem;
// //     padding: 4px 8px;
// //   }
// // `;

// // const InfoSection = styled.div`
// //   margin: 20px 0;

// //   h3 {
// //     font-size: 1.5rem;
// //     margin-bottom: 15px;

// //     @media (max-width: 768px) {
// //       font-size: 1.2rem;
// //     }

// //     @media (max-width: 576px) {
// //       font-size: 1rem;
// //     }
// //   }

// //   p {
// //     margin: 5px 0;
// //     font-size: 1rem;

// //     @media (max-width: 768px) {
// //       font-size: 0.9rem;
// //     }

// //     @media (max-width: 576px) {
// //       font-size: 0.85rem;
// //     }
// //   }
// // `;

// // const NoPayoutMessage = styled.p`
// //   border: 2px solid #f0e00f;
// //   background-color: #000033;
// //   color: #fff;
// //   padding: 10px;
// // `;

// // const ManagePayout = () => {
// //   const [payouts, setPayouts] = useState([]);
// //   const [loading, setLoading] = useState(true);
// //   const [error, setError] = useState(null);

// //   const fetchPayouts = async () => {
// //     try {
// //       setLoading(true);
// //       const response = await axios.get('https://server.digitalspayout.com/api/crypto/payouts');
// //       setPayouts(response.data.data.data || []); // Ensure payouts is an array
// //       setLoading(false);
// //     } catch (err) {
// //       setError('Error fetching payouts');
// //       setLoading(false);
// //     }
// //   };

// //   const approvePayout = async (payoutId) => {
// //     try {
// //       const response = await axios.put(`https://server.digitalspayout.com/api/payouts/${payoutId}/approve`);
// //       if (response.data.success) {
// //         Swal.fire('Success', response.data.message, 'success');
// //         fetchPayouts(); // Refresh list after approval
// //       }
// //     } catch (err) {
// //       Swal.fire('Error', 'Unable to approve payout', 'error');
// //     }
// //   };

// //   const rejectPayout = async (payoutId) => {
// //     try {
// //       const response = await axios.put(`https://server.digitalspayout.com/api/payouts/${payoutId}/reject`);
// //       if (response.data.success) {
// //         Swal.fire('Success', response.data.message, 'success');
// //         fetchPayouts(); // Refresh list after rejection
// //       }
// //     } catch (err) {
// //       Swal.fire('Error', 'Unable to reject payout', 'error');
// //     }
// //   };

// //   useEffect(() => {
// //     fetchPayouts();
// //   }, []);

// //   if (loading) {
// //     return <div className="loading">Loading payouts...</div>;
// //   }

// //   if (error) {
// //     return <div className="error">{error}</div>;
// //   }

// //   return (
// //     <Container>
// //       <Header>
// //         <h1>Manage Payouts</h1>
// //       </Header>
// //       {payouts.length === 0 ? (
// //         <NoPayoutMessage>No payouts available.</NoPayoutMessage>
// //       ) : (
// //         <ScrollableTableContainer>
// //           <Table>
// //             <thead>
// //               <tr>
// //                 <th>#</th>
// //                 <th>Depositor Name</th>
// //                 <th>Wallet Address</th>
// //                 <th>Amount</th>
// //                 <th>Currency</th>
// //                 <th>Plan Name</th>
// //                 <th>Subscription Type</th>
// //                 <th>Subscription Period</th>
// //                 <th>Requested Date</th>
// //                 <th>Status</th>
// //                 <th>Action</th>
// //               </tr>
// //             </thead>
// //             <tbody>
// //               {Array.isArray(payouts) && payouts.map((payout, index) => (
// //                 <tr key={payout.payoutId}>
// //                   <td>{index + 1}</td>
// //                   <td>{payout.depositorName}</td>
// //                   <td>{payout.walletAddress}</td>
// //                   <td>{payout.amount ? payout.amount.toLocaleString() : 'N/A'}</td>
// //                   <td>{` ${payout.currencyCode}(${payout.currencySymbol})`}</td>
// //                   <td>{payout.planName}</td>
// //                   <td>{payout.subscriptionType}</td>
// //                   <td>
// //                     {dayjs(payout.subscriptionStartDate).format('MMM DD, YYYY')} - {dayjs(payout.subscriptionEndDate).format('MMM DD, YYYY')}
// //                   </td>
// //                   <td>{dayjs(payout.payoutCreatedAt).format('MMM DD, YYYY')}</td>
// //                   <td>{payout.payoutStatus}</td>
// //                   <td>
// //                     {payout.payoutStatus === 'pending' ? (
// //                       <>
// //                         <Button variant="complete" onClick={() => approvePayout(payout.payoutId)}>Approve</Button>
// //                         <Button onClick={() => rejectPayout(payout.payoutId)}>Reject</Button>
// //                       </>
// //                     ) : (
// //                       <span>{payout.status ? payout.status.toUpperCase() : 'N/A'}</span>
// //                     )}
// //                   </td>
// //                 </tr>
// //               ))}
// //             </tbody>
// //           </Table>
// //         </ScrollableTableContainer>
// //       )}
// //     </Container>
// //   );
// // };

// // export default ManagePayout;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Swal from 'sweetalert2';
// import dayjs from 'dayjs';
// import styled from 'styled-components';

// const Container = styled.div`
//   padding: 20px;
//   background-color: #f8f9fa;

//   h2 {
//     color: #dc3545;
//     text-align: center;
//     margin-bottom: 20px;
//   }

//   .loading {
//     text-align: center;
//     font-size: 20px;
//     color: #007bff;
//   }

//   .error {
//     color: #dc3545;
//     text-align: center;
//     margin-bottom: 20px;
//     font-weight: bold;
//   }

//   .table-responsive {
//     overflow-x: auto;
//   }

//   table {
//     width: 100%;
//     border-collapse: collapse;
//     margin-top: 20px;
//     border-radius: 8px;
//     overflow: hidden;

//     thead {
//       background-color: #343a40;
//       color: white;
//     }

//     th,
//     td {
//       border: 2px solid #f0e00f;
//       padding: 15px;
//       text-align: center;

//       &:first-child {
//         width: 50px;
//       }
//     }

//     tbody tr {
//       background-color: #000033;
//       color: white;

//       &:hover {
//         background-color: #007bff;
//         transition: background-color 0.3s ease;
//       }

//       &:nth-child(even) {
//         background-color: #1c1c1c; // Alternating row color
//       }
//     }
//   }

//   button {
//     margin-right: 5px;
//     padding: 8px 12px;
//     font-size: 14px;
//     border-radius: 4px;
//     cursor: pointer;

//     &.btn-success {
//       background-color: #28a745;
//       border: none;
//       color: white;

//       &:hover {
//         background-color: #218838;
//       }
//     }

//     &.btn-danger {
//       background-color: #dc3545;
//       border: none;
//       color: white;

//       &:hover {
//         background-color: #c82333;
//       }
//     }
//   }

//   @media (max-width: 768px) {
//     h2 {
//       font-size: 24px;
//     }

//     table {
//       font-size: 12px;
//     }

//     button {
//       font-size: 12px;
//     }
//   }

//   @media (max-width: 576px) {
//     h2 {
//       font-size: 20px;
//     }

//     table {
//       font-size: 10px;
//     }

//     button {
//       font-size: 10px;
//     }
//   }
// `;

// const ManagePayout = () => {
//   const [payouts, setPayouts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const fetchPayouts = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get('https://server.digitalspayout.com/api/crypto/payouts');
//       setPayouts(response.data.data.data || []); // Ensure payouts is an array
//       setLoading(false);
//     } catch (err) {
//       setError('Error fetching payouts');
//       setLoading(false);
//     }
//   };

//   const approvePayout = async (payoutId) => {
//     try {
//       const response = await axios.put(`https://server.digitalspayout.com/api/payouts/${payoutId}/approve`);
//       if (response.data.success) {
//         Swal.fire('Success', response.data.message, 'success');
//         fetchPayouts(); // Refresh list after approval
//       }
//     } catch (err) {
//       Swal.fire('Error', 'Unable to approve payout', 'error');
//     }
//   };

//   const rejectPayout = async (payoutId) => {
//     try {
//       const response = await axios.put(`https://server.digitalspayout.com/api/payouts/${payoutId}/reject`);
//       if (response.data.success) {
//         Swal.fire('Success', response.data.message, 'success');
//         fetchPayouts(); // Refresh list after rejection
//       }
//     } catch (err) {
//       Swal.fire('Error', 'Unable to reject payout', 'error');
//     }
//   };

//   useEffect(() => {
//     fetchPayouts();
//   }, []);

//   if (loading) {
//     return <div className="loading">Loading payouts...</div>;
//   }

//   if (error) {
//     return <div className="error">{error}</div>;
//   }

//   return (
//     <Container>
//       <h2>Manage Payouts</h2>
//       {payouts.length === 0 ? (
//         <p style={{ border: '2px solid #f0e00f', backgroundColor: '#000033', color: '#fff', padding: '10px' }}>
//           No payouts available.
//         </p>
//       ) : (
//         <div className="table-responsive">
//           <table>
//             <thead>
//               <tr>
//                 <th>#</th>
//                 {/* <th>Payout ID</th> */}
//                 <th>Depositor Name</th>
//                 <th>Wallet Address</th>
//                 <th>Amount</th>
//                 <th>Currency</th>
//                 {/* <th>Plan Name</th> */}
//                 {/* <th>Subscription Type</th> */}
//                 <th>Subscription Period</th>
//                 <th>Requested Date</th>
//                 <th>Status</th>
//                 <th>Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {Array.isArray(payouts) && payouts.map((payout, index) => (
//                 <tr key={payout.payoutId}>
//                   <td>{index + 1}</td>
//                   {/* <td>{payout.payoutId}</td> */}
//                   <td>{payout.depositorName}</td>
//                   <td>{payout.walletAddress}</td>
//                   <td>{payout.amount ? payout.amount.toLocaleString() : 'N/A'}</td>
//                   <td>{` ${payout.currencyCode}(${payout.currencySymbol})`}</td>
//                   {/* <td>{payout.planName}</td> */}
//                   {/* <td>{payout.subscriptionType}</td> */}
//                   <td>
//                     {dayjs(payout.subscriptionStartDate).format('MMM DD, YYYY')} - {dayjs(payout.subscriptionEndDate).format('MMM DD, YYYY')}
//                   </td>
//                   {/* <td>{dayjs(payout.payoutCreatedAt).format('MMM DD, YYYY')}</td> */}
//                   <td>{payout.payoutStatus}</td>
//                   <td>
//                     {payout.payoutStatus === 'pending' ? (
//                       <>
//                         <button
//                           className="btn btn-success btn-sm"
//                           onClick={() => approvePayout(payout.payoutId)}
//                         >
//                           Approve
//                         </button>
//                         <button
//                           className="btn btn-danger btn-sm"
//                           onClick={() => rejectPayout(payout.payoutId)}
//                         >
//                           Reject
//                         </button>
//                       </>
//                     ) : (
//                       // <span>{payout.status ? payout.status.toUpperCase() : 'N/A'}</span>
//                       <>
//                       <button
//                         className="btn btn-success btn-sm"
//                         onClick={() => approvePayout(payout.payoutId)}
//                       >
//                         Approve
//                       </button>
//                       <button
//                         className="btn btn-danger btn-sm"
//                         onClick={() => rejectPayout(payout.payoutId)}
//                       >
//                         Reject
//                       </button>
//                     </>
//                     )}
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       )}
//     </Container>
//   );
// };

// export default ManagePayout;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Swal from 'sweetalert2';
// import styled from 'styled-components';
// import dayjs from 'dayjs';

// // Styled Components
// const Container = styled.div`
//   padding: 20px;
//   background-color: #000033;
//   color: #fff;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

// const Table = styled.table`
//   width: 100%;
//   max-width: 1200px;
//   border-collapse: collapse;
//   margin: 20px 0;
// `;

// const TableHeader = styled.th`
//   background-color: #f0e000;
//   color: #000033;
//   padding: 15px;
//   text-align: left;
// `;

// const TableRow = styled.tr`
//   &:nth-child(even) {
//     background-color: #0a0a2a; /* Updated to a darker shade */
//   }
// `;

// const TableCell = styled.td`
//   padding: 15px;
//   border: 1px solid #fff;
//   text-align: left;
// `;

// const Button = styled.button`
//   padding: 10px 20px;
//   margin: 10px;
//   border: none;
//   background-color: ${({ approve }) => (approve ? '#28a745' : '#dc3545')};
//   color: #fff;
//   font-size: 16px;
//   cursor: pointer;
//   border-radius: 5px;

//   &:hover {
//     opacity: 0.9;
//   }
// `;

// const Loading = styled.div`
//   font-size: 24px;
//   color: #fff;
//   margin-top: 20px;
// `;

// const Error = styled.div`
//   font-size: 24px;
//   color: #ff4d4d;
//   margin-top: 20px;
// `;

// // ManagePayouts Component
// const ManagePayouts = () => {
//   const [payouts, setPayouts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   // Fetch payouts from API
//   const fetchPayouts = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get('http://localhost:3005/api/crypto/payouts');
//       // const response = await axios.get('https://server.digitalspayout.com/api/crypto/payouts');
//       setPayouts(response.data.data.data || []); // Ensure payouts is an array
//       setLoading(false);
//     } catch (err) {
//       setError('Error fetching payouts');
//       setLoading(false);
//     }
//   };

//   // Handle payout approval or rejection
//   // const handlePayoutAction = async (payoutId, action) => {
//   //   try {
//   //     const response = await axios.put(`http://localhost:3005/api/crypto/admin/payout/${payoutId}`, {
//   //     // const response = await axios.put(`https://server.digitalspayout.com/api/crypto/admin/payout/${payoutId}`, {
//   //       action: action,
//   //     });


//   //     console.log('Response:', response.data || []); // Log the entire response
      
//   //     if (response.data.success) {
//   //       Swal.fire({
//   //         icon: 'success',
//   //         title: 'Success',
//   //         text: response.data.message,
//   //       });
//   //       // Refresh payouts after action
//   //       fetchPayouts(); // Call fetchPayouts to refresh the list
//   //     } else {
//   //       Swal.fire({
//   //         icon: 'error',
//   //         title: 'Error',
//   //         text: response.data.message,
//   //       });
//   //     }
//   //   } catch (error) {
//   //     console.error('Error handling payout action:', error);
//   //     Swal.fire({
//   //       icon: 'error',
//   //       title: 'Error',
//   //       text: 'An unexpected error occurred.',
//   //     });
//   //   }
//   // };

//   const handlePayoutAction = async (payoutId, action) => {
//     try {
//       console.log('Handling payout action for ID:', payoutId); // Log the payout ID
//       const response = await axios.put(`http://localhost:3005/api/crypto/admin/payout/${payoutId}`, {
//         action: action,
//       });
  
//       console.log(response.data); // Log the response to check structure
  
//       if (response.data.success) {
//         Swal.fire({
//           icon: 'success',
//           title: 'Success',
//           text: response.data.message,
//         });
//         fetchPayouts(); // Refresh payouts after action
//       } else {
//         Swal.fire({
//           icon: 'error',
//           title: 'Error',
//           text: response.data.message,
//         });
//       }
//     } catch (error) {
//       if (axios.isAxiosError(error)) {
//         console.error('Error handling payout action:', {
//           message: error.message,
//           response: error.response ? error.response.data : null,
//           status: error.response ? error.response.status : null,
//         });
//       } else {
//         console.error('Unexpected error:', error);
//       }
//       Swal.fire({
//         icon: 'error',
//         title: 'Error',
//         text: 'An unexpected error occurred. Please try again later.',
//       });
//     }
//   };
  
//   // useEffect to fetch payouts on mount
//   useEffect(() => {
//     fetchPayouts();
//   }, []);

//   return (
//     <Container>
//       <h2>Payout Requests</h2>
//       {loading && <Loading>Loading payouts...</Loading>}
//       {error && <Error>{error}</Error>}
//       {!loading && !error && (
//         <Table>
//           <thead>
//             <tr>
//               <TableHeader>ID</TableHeader>
  
//               <TableHeader>depositorName</TableHeader>
//               <TableHeader>Wallet ID</TableHeader>
//               <TableHeader>Amount</TableHeader>
//               <TableHeader>Status</TableHeader>
//               <TableHeader>Deposited Dated</TableHeader>
//               <TableHeader>Actions</TableHeader>
//             </tr>
//           </thead> 
//           <tbody>
//             {payouts.length > 0 ? (
//               payouts.map((payout, index) => (
//                 <TableRow key={payout.id}>
//                   <TableCell>{index + 1}</TableCell>
//                   <TableCell>{payout.depositorName}</TableCell>
//                   <TableCell>{payout.walletAddress}</TableCell>
//                   <TableCell>{payout.amount}</TableCell>
//                   <TableCell>{payout.status}</TableCell>
//                   <TableCell>{dayjs(payout.payoutCreatedAt).format('MMM DD, YYYY')}</TableCell>
//                   {/* <TableCell>
                    
//                     {payout.status === 'pending' && (
//                       <>
//                   <div className="btn-group btn-group-xs">

//                         <Button approve onClick={() => handlePayoutAction(payout.id, 'accepted')}>
//                           Approve
//                         </Button>
//                         <Button onClick={() => handlePayoutAction(payout.id, 'rejected')}>
//                           Reject
//                         </Button>
//                   </div>
//                       </>
//                     )}
                    
//                   </TableCell> */}
//                    <TableCell>
//       {payout.status === 'pending' && (
//         <>
//           <Button 
//             approve 
//             onClick={() => {
//               console.log('Handling payout ID:', payout.id); // Log the payout ID
//               handlePayoutAction(payout.id, 'accepted');
//             }}
//           >
//             Approve
//           </Button>
//           <Button 
//             onClick={() => {
//               console.log('Handling payout ID:', payout.id); // Log the payout ID
//               handlePayoutAction(payout.id, 'rejected');
//             }}
//           >
//             Reject
//           </Button>
//         </>
//       )}
//     </TableCell>
//                 </TableRow>
//               ))
//             ) : (
//               <TableRow>
//                 <TableCell colSpan="5">No payout requests available.</TableCell>
//               </TableRow>
//             )}
//           </tbody>
//         </Table>
//       )}
//     </Container>
//   );
// };

// export default ManagePayouts;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Swal from 'sweetalert2';
// import styled from 'styled-components';
// import dayjs from 'dayjs';
// import { useParams } from 'react-router-dom'; // Import useParams

// // Styled Components
// const Container = styled.div`
//   padding: 20px;
//   background-color: #000033;
//   color: #fff;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

// const Table = styled.table`
//   width: 100%;
//   max-width: 1200px;
//   border-collapse: collapse;
//   margin: 20px 0;
// `;

// const TableHeader = styled.th`
//   background-color: #f0e000;
//   color: #000033;
//   padding: 15px;
//   text-align: left;
// `;

// const TableRow = styled.tr`
//   &:nth-child(even) {
//     background-color: #0a0a2a; /* Updated to a darker shade */
//   }
// `;

// const TableCell = styled.td`
//   padding: 15px;
//   border: 1px solid #fff;
//   text-align: left;
// `;

// const Button = styled.button`
//   padding: 10px 20px;
//   margin: 10px;
//   border: none;
//   background-color: ${({ approve }) => (approve ? '#28a745' : '#dc3545')};
//   color: #fff;
//   font-size: 16px;
//   cursor: pointer;
//   border-radius: 5px;

//   &:hover {
//     opacity: 0.9;
//   }
// `;

// const Loading = styled.div`
//   font-size: 24px;
//   color: #fff;
//   margin-top: 20px;
// `;

// const Error = styled.div`
//   font-size: 24px;
//   color: #ff4d4d;
//   margin-top: 20px;
// `;

// // ManagePayouts Component
// const ManagePayouts = () => {
//   const { payoutId } = useParams(); // Get payoutId from the URL
//   const [payouts, setPayouts] = useState([]);
//   const [selectedPayout, setSelectedPayout] = useState(null); // State to store the selected payout
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   // Fetch payouts from API
//   const fetchPayouts = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get('http://localhost:3005/api/crypto/payouts');
//       setPayouts(response.data.data.data || []); // Ensure payouts is an array
//       setLoading(false);
//     } catch (err) {
//       setError('Error fetching payouts');
//       setLoading(false);
//     }
//   };

//   // Filter the payouts based on payoutId
//   useEffect(() => {
//     fetchPayouts();
//   }, []);

//   useEffect(() => {
//     if (payouts.length > 0 && payoutId) {
//       const filteredPayout = payouts.find(payout => payout.id === parseInt(payoutId));
//       setSelectedPayout(filteredPayout || null); // Store the selected payout or null if not found
//     }
//   }, [payouts, payoutId]);

//   // Handle payout approval or rejection
//   const handlePayoutAction = async (payoutId, action) => {
//     try {
//       console.log('Handling payout action for ID:', payoutId); // Log the payout ID
//       const response = await axios.put(`http://localhost:3005/api/crypto/admin/payout/${payoutId}`, {
//         action: action,
//       });

//       console.log(response.data); // Log the response to check structure

//       if (response.data.success) {
//         Swal.fire({
//           icon: 'success',
//           title: 'Success',
//           text: response.data.message,
//         });
//         fetchPayouts(); // Refresh payouts after action
//       } else {
//         Swal.fire({
//           icon: 'error',
//           title: 'Error',
//           text: response.data.message,
//         });
//       }
//     } catch (error) {
//       if (axios.isAxiosError(error)) {
//         console.error('Error handling payout action:', {
//           message: error.message,
//           response: error.response ? error.response.data : null,
//           status: error.response ? error.response.status : null,
//         });
//       } else {
//         console.error('Unexpected error:', error);
//       }
//       Swal.fire({
//         icon: 'error',
//         title: 'Error',
//         text: 'An unexpected error occurred. Please try again later.',
//       });
//     }
//   };

//   return (
//     <Container>
//       <h2>Payout Request Details</h2>
//       {loading && <Loading>Loading payout details...</Loading>}
//       {error && <Error>{error}</Error>}
//       {!loading && !error && payouts && (
//         <Table>
//           <thead>
            // <tr>
            //   <TableHeader>ID</TableHeader>
            //   <TableHeader>Depositor Name</TableHeader>
            //   <TableHeader>Wallet ID</TableHeader>
            //   <TableHeader>Amount</TableHeader>
            //   <TableHeader>Status</TableHeader>
            //   <TableHeader>Deposited Date</TableHeader>
            //   <TableHeader>Actions</TableHeader>
            // </tr>
//           </thead>
//           <tbody>
//             <TableRow>
//               <TableCell>{selectedPayout.id}</TableCell>
//               <TableCell>{selectedPayout.depositorName}</TableCell>
//               <TableCell>{selectedPayout.walletAddress}</TableCell>
//               <TableCell>{selectedPayout.amount}</TableCell>
//               <TableCell>{selectedPayout.status}</TableCell>
//               <TableCell>{dayjs(selectedPayout.payoutCreatedAt).format('MMM DD, YYYY')}</TableCell>
//               <TableCell>
//                 {selectedPayout.status === 'pending' && (
//                   <>
//                     <Button approve onClick={() => handlePayoutAction(selectedPayout.id, 'accepted')}>
//                       Approve
//                     </Button>
//                     <Button onClick={() => handlePayoutAction(selectedPayout.id, 'rejected')}>
//                       Reject
//                     </Button>
//                   </>
//                 )}
//               </TableCell>
//             </TableRow>
//           </tbody>
//         </Table>
//       )}
//       {!loading && !error && !selectedPayout && <Error>Payout not found.</Error>}
//     </Container>
//   );
// };

// export default ManagePayouts;



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import dayjs from 'dayjs';

// Styled Components for optimized responsiveness and scrollable table

const Container = styled.div`
  padding: 20px;
  background-color: #000033;
  color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  min-height: 100vh;
  padding: 20px;


  @media (max-width: 576px) {
    padding: 10px;
  }

  @media only screen and (max-width: 425px) {
    padding: 8px;
  }

  @media only screen and (max-width: 375px) {
    padding: 5px;
  }
  @media (max-width: 768px) {
    padding: 10px;
  }

  @media (max-width: 480px) {
    padding: 5px;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  max-height: 80vh;  /* Vertical scrollable area */
  overflow-x: auto;   /* Horizontal scrollable */
  overflow-y: auto;   /* Vertical scrollable */
  max-width: 100%;
  padding: 0 10px;
  margin: 0 auto;
  border: 1px solid #fff;

  /* Customize scrollbars */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #000033;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  min-width: 600px;
  white-space: nowrap; /* Prevent text from wrapping */

  @media (max-width: 1280px) {
    min-width: 100%;
  }

  @media (max-width: 768px) {
    min-width: 100%; /* Expand table to full width for small devices */
    font-size: 14px;
  }

  @media (max-width: 480px) {
    min-width: 100%; /* Full width for smaller screens */
    font-size: 12px;
  }
    padding: 12px 15px;
  border: 1px solid #fff;
  text-align: left;

  @media (max-width: 1024px) {
    padding: 10px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 13px;
  }

  @media (max-width: 480px) {
    padding: 6px;
    font-size: 12px;
    display: block; /* Show as block element */
    width: 100%;
  }
`;

const TableHeader = styled.th`
  background-color: #f0e000;
  color: #000033;
  padding: 12px 15px;
  text-align: left;
  position: sticky; /* Fix table headers during vertical scroll */
  top: 0;
  z-index: 10;

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 8px;
    font-size: 12px;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #0a0a2a;
  }

  @media (max-width: 480px) {
    display: block; /* Allow block structure for very small devices */
  }
`;

const TableCell = styled.td`
  padding: 12px 15px;
  border: 1px solid #fff;
  text-align: left;

  @media (max-width: 1024px) {
    padding: 10px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 13px;
  }

  @media (max-width: 480px) {
    padding: 6px;
    font-size: 12px;
    display: block; /* Show as block element */
    width: 100%;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  margin: 10px;
  border: none;
  background-color: ${({ approve }) => (approve ? '#28a745' : '#dc3545')};
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 6px 12px;
    font-size: 12px;
  }
`;

const Loading = styled.div`
  font-size: 24px;
  color: #fff;
  margin-top: 20px;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const Error = styled.div`
  font-size: 24px;
  color: #ff4d4d;
  margin-top: 20px;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;


// ManagePayouts Component
const ManagePayouts = () => {
  const [payouts, setPayouts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPayouts = async () => {
    try {
      setLoading(true);
      // const response = await axios.get('https://server.digitalspayout.com/api/crypto/payouts');
      const response = await axios.get('https://server.digitalspayout.com/api/crypto/unpublished/payouts');
      console.log('Payouts response:', response.data); // Log the response
      setPayouts(response.data.data.data || []); // Ensure payouts is an array
      setLoading(false);
    } catch (err) {
      setError('Error fetching payouts');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPayouts();
  }, []);

  const handleApprove = async (payoutId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to approve this payout request?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#dc3545',
      confirmButtonText: 'Yes, approve it!',
    });

    if (result.isConfirmed) {
      try {
        // const response = await axios.put(`http://localhost:3005/api/crypto/approve/${payoutId}`);
        const response = await axios.put(`https://server.digitalspayout.com/api/crypto/approve/${payoutId}`);
        if (response.data.success) {
          Swal.fire('Approved!', response.data.message, 'success');
          fetchPayouts(); // Refresh payouts after approval
        } else {
          Swal.fire('Error!', response.data.message, 'error');
        }
      } catch (error) {
        Swal.fire('Error!', 'Failed to approve payout request.', 'error');
      }
    }
  };

  const handleReject = async (payoutId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to reject this payout request?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#28a745',
      confirmButtonText: 'Yes, reject it!',
    });

    if (result.isConfirmed) {
      try {
        // const response = await axios.put(`http://localhost:3005/api/crypto/reject/${payoutId}`);
        const response = await axios.put(`https://server.digitalspayout.com/api/crypto/reject/${payoutId}`);
        if (response.data.success) {
          Swal.fire('Rejected!', response.data.message, 'success');
          fetchPayouts(); // Refresh payouts after rejection
        } else {
          Swal.fire('Error!', response.data.message, 'error');
        }
      } catch (error) {
        Swal.fire('Error!', 'Failed to reject payout request.', 'error');
      }
    }
  };

  return (
    <Container>
      <h1>Manage Payouts</h1>
      {loading && <Loading>Loading payouts...</Loading>}
      {error && <Error>{error}</Error>}
      {!loading && !error && (
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <TableHeader>#</TableHeader>
                <TableHeader>Depositor Name</TableHeader>
                <TableHeader>Wallet Address</TableHeader>
                <TableHeader>Amount</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader>Created At</TableHeader>
                <TableHeader>Actions</TableHeader>
              </tr>
            </thead>
            <tbody>
              {payouts.length > 0 ? (
                payouts.map((payout, index) => (
                  <TableRow key={payout.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{payout.depositorName}</TableCell>
                    <TableCell>{payout.walletAddress}</TableCell>
                    <TableCell>{payout.amount}</TableCell>
                    <TableCell>{payout.status}</TableCell>
                    <TableCell>{dayjs(payout.payoutCreatedAt).format('MMM DD, YYYY')}</TableCell>
                    <TableCell>
                      <div className="btn-group btn-group-xs">
                        <Button approve onClick={() => handleApprove(payout.payoutId)}>Approve</Button>
                        <Button onClick={() => handleReject(payout.payoutId)}>Reject</Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="7">No payouts available</TableCell>
                </TableRow>
              )}
            </tbody>
          </Table>
        </TableWrapper>
      )}
    </Container>
  );
};

export default ManagePayouts;

// Styled Components
// const Container = styled.div`
//   padding: 20px;
//   background-color: #000033;
//   color: #fff;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

// const Table = styled.table`
//   width: 100%;
//   max-width: 1200px;
//   border-collapse: collapse;
//   margin: 20px 0;
// `;

// const TableHeader = styled.th`
//   background-color: #f0e000;
//   color: #000033;
//   padding: 15px;
//   text-align: left;
// `;

// const TableRow = styled.tr`
//   &:nth-child(even) {
//     background-color: #0a0a2a;
//   }
// `;

// const TableCell = styled.td`
//   padding: 15px;
//   border: 1px solid #fff;
//   text-align: left;
// `;

// const Button = styled.button`
//   padding: 10px 20px;
//   margin: 10px;
//   border: none;
//   background-color: ${({ approve }) => (approve ? '#28a745' : '#dc3545')};
//   color: #fff;
//   font-size: 16px;
//   cursor: pointer;
//   border-radius: 5px;

//   &:hover {
//     opacity: 0.9;
//   }
// `;

// const Loading = styled.div`
//   font-size: 24px;
//   color: #fff;
//   margin-top: 20px;
// `;

// const Error = styled.div`
//   font-size: 24px;
//   color: #ff4d4d;
//   margin-top: 20px;
// `;

// // ManagePayouts Component
// const ManagePayouts = () => {
//   const [payouts, setPayouts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
// // const [payoutId, setPayoutId] = useState(null)

//   // Fetch payouts from API
//   const fetchPayouts = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get('https://server.digitalspayout.com/api/crypto/payouts');
//       // const response = await axios.get('http://localhost:3005/api/crypto/unpublished/payouts');
//       // const response = await axios.get('https://server.digitalspayout.com/api/crypto/unpublished/payouts');
//       console.log('Payouts response:', response.data); // Log the response
//       setPayouts(response.data.data.data || []); // Ensure payouts is an array
//       setLoading(false);
//     } catch (err) {
//       setError('Error fetching payouts');
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchPayouts();
//   }, []);

//   // Handle approve payout request
//   const handleApprove = async (payoutId) => {
//     const result = await Swal.fire({
//       title: 'Are you sure?',
//       text: "Do you want to approve this payout request?",
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#28a745',
//       cancelButtonColor: '#dc3545',
//       confirmButtonText: 'Yes, approve it!'
//     });

//     if (result.isConfirmed) {
//       try {
//         const response = await axios.put(`http://localhost:3005/api/crypto/approve/${payoutId}`);
//         if (response.data.success) {
//           Swal.fire('Approved!', response.data.message, 'success');
//           fetchPayouts(); // Refresh payouts after approval
//         } else {
//           Swal.fire('Error!', response.data.message, 'error');
//         }
//       } catch (error) {
//         Swal.fire('Error!', 'Failed to approve payout request.', 'error');
//       }
//     }
//   };

//   // Handle reject payout request
//   const handleReject = async (payoutId) => {
//     const result = await Swal.fire({
//       title: 'Are you sure?',
//       text: "Do you want to reject this payout request?",
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#dc3545',
//       cancelButtonColor: '#28a745',
//       confirmButtonText: 'Yes, reject it!'
//     });

//     if (result.isConfirmed) {
//       try {
//         const response = await axios.put(`http://localhost:3005/api/crypto/reject/${payoutId}`);
//         if (response.data.success) {
//           Swal.fire('Rejected!', response.data.message, 'success');
//           fetchPayouts(); // Refresh payouts after rejection
//         } else {
//           Swal.fire('Error!', response.data.message, 'error');
//         }
//       } catch (error) {
//         Swal.fire('Error!', 'Failed to reject payout request.', 'error');
//       }
//     }
//   };

//   return (
//     <Container>
//       <h1>Manage Payouts</h1>
//       {loading && <Loading>Loading payouts...</Loading>}
//       {error && <Error>{error}</Error>}
//       {!loading && !error && (
//         <Table>
//           <thead>
//             <tr>
//               <TableHeader>#</TableHeader>
//               <TableHeader>Depositor Name</TableHeader>
//               <TableHeader>Wallet Address</TableHeader>
//               <TableHeader>Amount</TableHeader>
//               <TableHeader>Status</TableHeader>
//               <TableHeader>Created At</TableHeader>
//               <TableHeader>Actions</TableHeader>
//             </tr>
//           </thead>
//           {/* <tbody>
//             {payouts.length > 0 ? (
//               payouts.map((payout, index) => (
//                 <TableRow key={payout.id}>
//                   <TableCell>{index + 1}</TableCell>
//                   <TableCell>{payout.depositorName}</TableCell>
//                   <TableCell>{payout.walletAddress}</TableCell>
//                   <TableCell>{payout.amount}</TableCell>
//                   <TableCell>{payout.status}</TableCell>
//                   <TableCell>{dayjs(payout.payoutCreatedAt).format('MMM DD, YYYY')}</TableCell>
//                   <TableCell>
//                   <div className="btn-group btn-group-xs">
//                     <Button approve onClick={() => handleApprove(payout.payoutId)}>Approve</Button>
//                     <Button onClick={() => handleReject(payout.payoutId)}>Reject</Button>
//                   </div>
//                   </TableCell>
//                 </TableRow>
//               ))
//             ) : (
//               <TableRow>
//                 <TableCell colSpan="7" style={{ textAlign: 'center' }}>
//                   No payout requests available.
//                 </TableCell>
//               </TableRow>
//             )}
//           </tbody> */}
//           <tbody>
//             {payouts.length > 0 ? (
//               payouts.map((payout, index) => {
//                 console.log('Payout ID:', payout.payoutId); // Log payout.id to console

//                 return (
//                   <TableRow key={payout.payoutId}>
//                     <TableCell>{index + 1}</TableCell>
//                     <TableCell>{payout.depositorName}</TableCell>
//                     <TableCell>{payout.walletAddress}</TableCell>
//                     <TableCell>{payout.amount}</TableCell>
//                     <TableCell>{payout.status}</TableCell>
//                     <TableCell>{dayjs(payout.payoutCreatedAt).format('MMM DD, YYYY')}</TableCell>
//                     <TableCell>
//                       <div className="btn-group btn-group-xs">
//                         <Button approve onClick={() => handleApprove(payout.payoutId)}>Approve</Button>
//                         <Button onClick={() => handleReject(payout.payoutId)}>Reject</Button>
//                       </div>
//                     </TableCell>
//                   </TableRow>
//                 );
//               })
//             ) : (
//               <TableRow>
//                 <TableCell colSpan="7" style={{ textAlign: 'center' }}>
//                   No payout requests available.
//                 </TableCell>
//               </TableRow>
//             )}
//           </tbody>

//         </Table>
//       )}
//     </Container>
//   );
// };

// export default ManagePayouts;
