// import React, { useEffect, useState } from 'react';
// import { Button, Modal, Form } from 'react-bootstrap';
// import { FaArrowDown, FaWallet, FaLock } from 'react-icons/fa';
// import { useAuth } from './../AuthContext';
// import { useNavigate } from 'react-router-dom';
// import Swal from 'sweetalert2';
// import axios from 'axios';
// import styled from 'styled-components';

// const Container = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   padding: 20px;
//   background-color: #000033;

//   @media (max-width: 768px) {
//     flex-direction: column;
//     padding: 10px;
//   }
// `;

// const CardHolder = styled.div`
//   background-color: #000033;
//   color: #fff;
//   font-size: 14px;
//   line-height: 1.4;
//   border-radius: 10px;
//   padding: 20px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   max-width: 600px;
//   width: 100%;
//   margin: 10px;

//   @media (max-width: 768px) {
//     padding: 15px;
//     margin: 0;
//   }
// `;

// const WalletWidget = styled.div`
//   background-color: #fff;
//   padding: 20px;
//   border-radius: 10px;
//   margin-bottom: 15px;

//   @media (max-width: 768px) {
//     padding: 15px;
//   }
// `;

// const Heading = styled.h5`
//   font-weight: 600;
//   color: #000;
//   background-color: #fff;
// `;

// const Label = styled.span`
//   color: #fff;
//   background-color: #000033;
// `;

// const Select = styled(Form.Control)`
//   background-color: #000033;
//   color: #fff;
//   padding: 8px;
//   border-radius: 5px;

//   @media (max-width: 768px) {
//     padding: 6px;
//   }
// `;

// const WalletDetails = styled.div`
//   background-color: #000033;
//   color: #fff;
//   background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./images/golden-coin.jpg');
//   padding: 15px;
//   border-radius: 10px;

//   @media (max-width: 768px) {
//     padding: 10px;
//   }
// `;

// const WalletCard = () => {
//   const { state } = useAuth();
//   const user = state?.user?.user;
//   const userId = user?.id;
//   const navigate = useNavigate();

//   const [subscriptions, setSubscriptions] = useState({});
//   const [selectedSubscription, setSelectedSubscription] = useState('');
//   const [selectedPlan, setSelectedPlan] = useState(null);
//   const [showDepositModal, setShowDepositModal] = useState(false);
//   const [showWithdrawModal, setShowWithdrawModal] = useState(false);
//   const [depositAmount, setDepositAmount] = useState('');
//   const [withdrawAmount, setWithdrawAmount] = useState('');
//   const [selectedCurrency, setSelectedCurrency] = useState('');
//   const [currencies, setCurrencies] = useState([]);
//   const [proofOfPayment, setProofOfPayment] = useState(null);
//   const [selectedWallet, setSelectedWallet] = useState(null);

//   useEffect(() => {
//     if (!userId) {
//       Swal.fire('Error', 'User not authenticated', 'error');
//       return;
//     }

//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`https://server.digitalspayout.com/api/crypto/wallet/user-wallet-subscription-details/${userId}`);
//         if (response.data.success) {
//           const data = response.data.data;
//           setSubscriptions(data.subscriptions || {});
//           if (Object.keys(data.subscriptions).length > 0) {
//             const firstPlan = Object.keys(data.subscriptions)[0];
//             setSelectedSubscription(firstPlan);
//             setSelectedPlan(data.subscriptions[firstPlan][0] || null);
//             setSelectedWallet(data.wallets[firstPlan]?.[0] || null);
//           }

//           const currenciesResponse = await axios.get('https://server.digitalspayout.com/api/crypto/currencies');
//           setCurrencies(currenciesResponse.data.data || []);
//         } else {
//           Swal.fire('Error', response.data.message, 'error');
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         Swal.fire('Error', 'Failed to fetch data', 'error');
//       }
//     };

//     fetchData();
//   }, [userId]);

//   const handleSubscriptionChange = (e) => {
//     const planName = e.target.value;
//     setSelectedSubscription(planName);
//     setSelectedPlan(subscriptions[planName]?.[0] || null);
//   };

//   const handleDepositShow = () => setShowDepositModal(true);
//   const handleDepositClose = () => setShowDepositModal(false);
//   const handleWithdrawShow = () => setShowWithdrawModal(true);
//   const handleWithdrawClose = () => setShowWithdrawModal(false);

//   const handleFileChange = (e) => {
//     setProofOfPayment(e.target.files[0]);
//   };

//   const handleDeposit = async (e) => {
//     e.preventDefault();
//     if (!selectedPlan) {
//       Swal.fire('Error', 'Please select a valid subscription plan', 'error');
//       return;
//     }

//     const formData = new FormData();
//     formData.append('amount', depositAmount);
//     formData.append('currencyId', selectedCurrency);
//     formData.append('walletId', selectedPlan.deposit.id);
//     formData.append('userId', userId);

//     if (proofOfPayment) {
//       formData.append('proofOfPayment', proofOfPayment);
//     }

//     try {
//       const response = await axios.post('https://server.digitalspayout.com/api/crypto/wallet/deposit', formData, {
//         headers: { 'Content-Type': 'multipart/form-data' }
//       });
//       if (response.data.success) {
//         Swal.fire('Success', 'Deposit completed successfully', 'success');
//         setShowDepositModal(false);
//       } else {
//         Swal.fire('Error', response.data.message, 'error');
//       }
//     } catch (error) {
//       console.error('Deposit Error:', error);
//       Swal.fire('Error', 'Failed to complete the deposit', 'error');
//     }
//   };

//   const handleWithdraw = async (e) => {
//     e.preventDefault();
//     if (!selectedPlan) {
//       Swal.fire('Error', 'Please select a valid subscription plan', 'error');
//       return;
//     }

//     try {
//       const response = await axios.post('https://server.digitalspayout.com/api/crypto/payout/request', {
//         userId,
//         walletId: selectedPlan.deposit.id,
//         amount: withdrawAmount,
//         currencyId: selectedCurrency
//       });

//       if (response.data.success) {
//         Swal.fire('Success', 'Withdrawal completed successfully', 'success');
//         setShowWithdrawModal(false);
//       } else {
//         Swal.fire('Error', response.data.message, 'error');
//       }
//     } catch (error) {
//       console.error('Withdrawal Error:', error);
//       Swal.fire('Error', 'Failed to complete the withdrawal', 'error');
//     }
//   };

//   return (
//     <Container>
//       <CardHolder>
//         <img src='../images/golden-coin.jpg' alt='wallet Home' style={{ width: "100px", textAlign: "center", fontWeight: "bold" }} />
//         <WalletWidget>
//           <div className="d-flex justify-content-between align-items-center mb-3">
//             <Heading>Welcome, {user?.firstName} {user?.lastName}</Heading>
//             <FaWallet size={24} />
//           </div>

//           <div className="d-flex justify-content-between mb-2">
//             <Label>Subscription Plan:</Label>
//             <Label><FaLock className="me-2" /> {selectedSubscription}</Label>
//           </div>

//           <Form.Group controlId="subscriptionSelect">
//             <Form.Label className="text-primary text-uppercase mb-1">Select Subscription Plan</Form.Label>
//             <Select as="select" value={selectedSubscription} onChange={handleSubscriptionChange}>
//               {Object.keys(subscriptions).length > 0 ? (
//                 Object.keys(subscriptions).map(planName => (
//                   <option key={planName} value={planName}>
//                     {planName}
//                   </option>
//                 ))
//               ) : (
//                 <option value="">No Plans Available</option>
//               )}
//             </Select>
//           </Form.Group>

//           {selectedPlan && (
//             <>
//               <div className="d-flex justify-content-between align-items-center mb-3">
//                 <span className="text-bold">Deposit Amount</span>
//                 <span><FaArrowDown className="me-2 text-success" /> ${selectedPlan.deposit.amount}</span>
//               </div>
//               <div className="d-flex justify-content-between align-items-center mb-3">
//                 <span className="text-bold">Currency</span>
//                 <span><FaArrowDown className="me-2 text-success" /> {selectedPlan.currency.currencyName}</span>
//               </div>
//               <div className="d-flex justify-content-between align-items-center mb-3">
//                 <Label>Proof of Payment:</Label>
//                 <Button variant="primary" onClick={handleDepositShow}>Deposit</Button>
//               </div>
//             </>
//           )}
//         </WalletWidget>
//       </CardHolder>

//       {/* Deposit Modal */}
//       <Modal show={showDepositModal} onHide={handleDepositClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Deposit Funds</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form onSubmit={handleDeposit}>
//             <Form.Group controlId="depositAmount">
//               <Form.Label>Deposit Amount</Form.Label>
//               <Form.Control type="number" placeholder="Enter amount" value={depositAmount} onChange={(e) => setDepositAmount(e.target.value)} required />
//             </Form.Group>
//             <Form.Group controlId="currencySelect">
//               <Form.Label>Select Currency</Form.Label>
//               <Select as="select" value={selectedCurrency} onChange={(e) => setSelectedCurrency(e.target.value)} required>
//                 <option value="">Choose currency</option>
//                 {currencies.map((currency) => (
//                   <option key={currency.id} value={currency.id}>{currency.currencyName}</option>
//                 ))}
//               </Select>
//             </Form.Group>
//             <Form.Group controlId="proofOfPayment">
//               <Form.Label>Upload Proof of Payment</Form.Label>
//               <Form.Control type="file" onChange={handleFileChange} required />
//             </Form.Group>
//             <Button variant="primary" type="submit">Submit</Button>
//           </Form>
//         </Modal.Body>
//       </Modal>

//       {/* Withdraw Modal */}
//       <Modal show={showWithdrawModal} onHide={handleWithdrawClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Withdraw Funds</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form onSubmit={handleWithdraw}>
//             <Form.Group controlId="withdrawAmount">
//               <Form.Label>Withdraw Amount</Form.Label>
//               <Form.Control type="number" placeholder="Enter amount" value={withdrawAmount} onChange={(e) => setWithdrawAmount(e.target.value)} required />
//             </Form.Group>
//             <Form.Group controlId="currencySelect">
//               <Form.Label>Select Currency</Form.Label>
//               <Select as="select" value={selectedCurrency} onChange={(e) => setSelectedCurrency(e.target.value)} required>
//                 <option value="">Choose currency</option>
//                 {currencies.map((currency) => (
//                   <option key={currency.id} value={currency.id}>{currency.currencyName}</option>
//                 ))}
//               </Select>
//             </Form.Group>
//             <Button variant="primary" type="submit">Submit</Button>
//           </Form>
//         </Modal.Body>
//       </Modal>
//     </Container>
//   );
// };

// export default WalletCard;



// import React, { useEffect, useState } from 'react';
// import { Button, Modal, Form } from 'react-bootstrap';
// import { FaArrowDown, FaWallet, FaLock } from 'react-icons/fa';
// import { useAuth } from './../AuthContext';
// import { useNavigate } from 'react-router-dom';
// import Swal from 'sweetalert2';
// import axios from 'axios';
// import './walletCard.css';

// const WalletCard = () => {
//   const { state } = useAuth();
//   const user = state?.user?.user;
//   const userId = user?.id;
//   const navigate = useNavigate();

//   const [subscriptions, setSubscriptions] = useState({});
//   const [selectedSubscription, setSelectedSubscription] = useState('');
//   const [selectedPlan, setSelectedPlan] = useState(null);
//   const [showDepositModal, setShowDepositModal] = useState(false);
//   const [showWithdrawModal, setShowWithdrawModal] = useState(false);
//   const [depositAmount, setDepositAmount] = useState('');
//   const [withdrawAmount, setWithdrawAmount] = useState('');
//   const [selectedCurrency, setSelectedCurrency] = useState('');
//   const [currencies, setCurrencies] = useState([]);
//   const [proofOfPayment, setProofOfPayment] = useState(null);
//   const [selectedWallet, setSelectedWallet] = useState(null);

//   // Fetch subscriptions data on component mount
//   useEffect(() => {
//     if (!userId) {
//       Swal.fire('Error', 'User not authenticated', 'error');
//       return;
//     }

//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`https://server.digitalspayout.com/api/crypto/wallet/user-wallet-subscription-details/${userId}`);
//         if (response.data.success) {
//           const data = response.data.data;
//           console.log('Subscriptions Data:', data);
//           setSubscriptions(data.subscriptions || {});
//           if (Object.keys(data.subscriptions).length > 0) {
//             const firstPlan = Object.keys(data.subscriptions)[0];
//             setSelectedSubscription(firstPlan);
//             setSelectedPlan(data.subscriptions[firstPlan][0] || null);
//             setSelectedWallet(data.wallets[firstPlan]?.[0] || null);
//           }

//           const currenciesResponse = await axios.get('https://server.digitalspayout.com/api/crypto/currencies');
//           setCurrencies(currenciesResponse.data.data || []);
//           // console.log('====================================');
//           // console.log('currencies:', currenciesResponse.data.currencies);
//           // console.log('currencies2:', currenciesResponse.data);
//           // console.log('currencies3:', currenciesResponse);
//           // console.log('====================================');
//         } else {
//           Swal.fire('Error', response.data.message, 'error');
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         Swal.fire('Error', 'Failed to fetch data', 'error');
//       }
//     };

//     fetchData();
//   }, [userId]);

//   // Handle subscription change
//   const handleSubscriptionChange = (e) => {
//     const planName = e.target.value;
//     setSelectedSubscription(planName);
//     setSelectedPlan(subscriptions[planName]?.[0] || null); // Update selected plan when a subscription changes
//   };

//   const handleDepositShow = () => setShowDepositModal(true);
//   const handleDepositClose = () => setShowDepositModal(false);
//   const handleWithdrawShow = () => setShowWithdrawModal(true);
//   const handleWithdrawClose = () => setShowWithdrawModal(false);

//   const handleFileChange = (e) => {
//     setProofOfPayment(e.target.files[0]);
//   };

//   // Handle deposit
//   const handleDeposit = async (e) => {
//     e.preventDefault();

//     if (!selectedPlan) {
//       Swal.fire('Error', 'Please select a valid subscription plan', 'error');
//       return;
//     }

//     const formData = new FormData();
//     formData.append('amount', depositAmount);
//     formData.append('currencyId', selectedCurrency);
//     formData.append('walletId', selectedPlan.deposit.id); // Assuming you're using the deposit ID here
//     formData.append('userId', userId);

//     if (proofOfPayment) {
//       formData.append('proofOfPayment', proofOfPayment);
//     }

//     try {
//       const response = await axios.post('https://server.digitalspayout.com/api/crypto/wallet/deposit', formData, {
//         headers: { 'Content-Type': 'multipart/form-data' }
//       });
//       if (response.data.success) {
//         Swal.fire('Success', 'Deposit completed successfully', 'success');
//         setShowDepositModal(false);
//       } else {
//         Swal.fire('Error', response.data.message, 'error');
//       }
//     } catch (error) {
//       console.error('Deposit Error:', error);
//       Swal.fire('Error', 'Failed to complete the deposit', 'error');
//     }
//   };

//   // Handle withdrawal
//   const handleWithdraw = async (e) => {
//     e.preventDefault();

//     if (!selectedPlan) {
//       Swal.fire('Error', 'Please select a valid subscription plan', 'error');
//       return;
//     }

//     try {
//       const response = await axios.post('https://server.digitalspayout.com/api/crypto/payout/request', {
//         userId,
//         walletId: selectedPlan.deposit.id, // Assuming you're using the deposit ID here
//         amount: withdrawAmount,
//         currencyId: selectedCurrency
//       });

//       if (response.data.success) {
//         Swal.fire('Success', 'Withdrawal completed successfully', 'success');
//         setShowWithdrawModal(false);
//       } else {
//         Swal.fire('Error', response.data.message, 'error');
//       }
//     } catch (error) {
//       console.error('Withdrawal Error:', error);
//       Swal.fire('Error', 'Failed to complete the withdrawal', 'error');
//     }
//   };

//   return (
//     // <div className="container align-items-center justify-content-center" style={{ height: '100%' }}>
//     //   <div className="row align-items-center justify-content-center" style={{ height: '100%', backgroundColor:'#000033' }}>
//     //     <div className="col-xl-6 col-md-8">
//     //       <img src='../images/golden-coin.jpg' alt='wallet Home'
//     //            style={{ width: "100px", textAlign: "center", fontWeight: "bold" }}
//     //       />
//     //       <div className="card-holder card shadow p-4 mb-5">
//     //         <div className="wallet-widget">
//     //           <div className="d-flex justify-content-between align-items-center mb-3">
//     //             <h5  style={{ height: '100%', backgroundColor:'#000033', color:'#fff' }}>Welcome, {user?.firstName} {user?.lastName}</h5>
//     //             <FaWallet size={24} />
//     //           </div>

//     //           <div className="d-flex justify-content-between mb-2">
//     //             <span className="text-bold" style={{ backgroundColor:'#000033', color:'#fff' }}>Subscription Plan:</span>
//     //             <span style={{ backgroundColor:'#000033', color:'#fff' }}><FaLock className="me-2" /> {selectedSubscription}</span>
//     //           </div>

//     //           <Form.Group controlId="subscriptionSelect">
//     //             <Form.Label className="text-primary text-uppercase mb-1" style={{ backgroundColor: "#000033", color: "#fff" }}>
//     //               Select Subscription Plan
//     //             </Form.Label>
//     //             <Form.Control
//     //               as="select"
//     //               style={{ backgroundColor: '#000033', color: '#FFF' }}
//     //               value={selectedSubscription}
//     //               onChange={handleSubscriptionChange}
//     //             >
//     //               {Object.keys(subscriptions).length > 0 ? (
//     //                 Object.keys(subscriptions).map(planName => (
//     //                   <option key={planName} value={planName}>
//     //                     {planName}
//     //                   </option>
//     //                 ))
//     //               ) : (
//     //                 <option value="">No Plans Available</option>
//     //               )}
//     //             </Form.Control>
//     //           </Form.Group>

//     //           {selectedPlan && (
//     //             <>
//     //               <div className="d-flex justify-content-between align-items-center mb-3">
//     //                 <span className="text-bold"style={{ color:'#000033' }} >Deposit Amount</span>
//     //                 <span style={{ backgroundColor:'#000033', color:'#fff' }}><FaArrowDown className="me-2 text-success" /> ${selectedPlan.deposit.amount}</span>
//     //               </div>
//     //               <div className="d-flex justify-content-between align-items-center mb-3">
//     //                 <span className="text-bold" style={{  color:'#000033' }}>Currency</span>
//     //                 <span style={{  backgroundColor:'#000033', color:'#fff' }}><FaArrowDown className="me-2 text-success" /> {selectedPlan.currency.currencyName} ({selectedPlan.currency.symbol})</span>
//     //               </div>
//     //               <div className="wallet-widget shadow-sm p-3" style={{ backgroundColor: "#000033", color: "#fff", backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('./images/golden-coin.jpg')" }}>
//     //                 <p>Total Amount: ${selectedPlan.totalAmount}</p>
//     //                 <p>Plan Start Date: {new Date(selectedPlan.startDate).toLocaleDateString()}</p>
//     //                 <p>Plan End Date: {new Date(selectedPlan.endDate).toLocaleDateString()}</p>
//     //                 <p>Wallet Deposit Status: {selectedPlan.deposit.status}</p>
//     //                 <div className="d-flex justify-content-between align-items-center mb-3">
//     //                   <span className="text" style={{color:'green'}}>Total Invested Balance</span>
//     //                   <span style={{color:'green', fontSize:'bold', fontWeight:'bolder'}}><FaArrowDown className="me-2 text-success" style={{color:'green'}}/> ${selectedWallet?.balance || '0.00'}</span>
//     //                 </div>
//     //                 <div className="button-group">
//     //                   <Button variant="outline-primary" onClick={handleWithdrawShow}>Withdraw</Button>
//     //                   <Button variant="outline-primary" onClick={handleDepositShow}>Deposit</Button>
//     //                 </div>
//     //               </div>
//     //             </>
//     //           )}
//     //         </div>

//     //         {/* Deposit Modal */}
//     //         <Modal show={showDepositModal} onHide={handleDepositClose}>
//     //           <Modal.Header closeButton>
//     //             <Modal.Title>Deposit</Modal.Title>
//     //           </Modal.Header>
//     //           <Modal.Body>
//     //             <Form onSubmit={handleDeposit}>
//     //               <Form.Group controlId="depositAmount">
//     //                 <Form.Label>Deposit Amount</Form.Label>
//     //                 <Form.Control
//     //                   type="number"
//     //                   placeholder="Enter deposit amount"
//     //                   value={depositAmount}
//     //                   onChange={(e) => setDepositAmount(e.target.value)}
//     //                   required
//     //                 />
//     //               </Form.Group>
//     //               <Form.Group controlId="currencySelect">
//     //                 <Form.Label>Select Currency</Form.Label>
//     //                 <Form.Control
//     //                   as="select"
//     //                   value={selectedCurrency}
//     //                   onChange={(e) => setSelectedCurrency(e.target.value)}
//     //                   required
//     //                 >
//     //                   <option value="">Select Currency</option>
//     //                   {currencies.map((currency) => (
//     //                     <option key={currency.id} value={currency.id}>
//     //                       {currency.name} ({currency.symbol})
//     //                     </option>
//     //                   ))}
//     //                 </Form.Control>
//     //               </Form.Group>
//     //               <Form.Group controlId="proofOfPayment">
//     //                 <Form.Label>Proof of Payment</Form.Label>
//     //                 <Form.Control
//     //                   type="file"
//     //                   accept="image/*"
//     //                   onChange={handleFileChange}
//     //                 />
//     //               </Form.Group>
//     //               <Button variant="primary" type="submit" className="mt-3">Submit</Button>
//     //             </Form>
//     //           </Modal.Body>
//     //         </Modal>

//     //         {/* Withdraw Modal */}
//     //         <Modal show={showWithdrawModal} onHide={handleWithdrawClose}>
//     //           <Modal.Header closeButton>
//     //             <Modal.Title>Withdraw</Modal.Title>
//     //           </Modal.Header>
//     //           <Modal.Body>
//     //             <Form onSubmit={handleWithdraw}>
//     //               <Form.Group controlId="withdrawAmount">
//     //                 <Form.Label>Withdraw Amount</Form.Label>
//     //                 <Form.Control
//     //                   type="number"
//     //                   placeholder="Enter withdrawal amount"
//     //                   value={withdrawAmount}
//     //                   onChange={(e) => setWithdrawAmount(e.target.value)}
//     //                   required
//     //                 />
//     //               </Form.Group>
//     //               <Form.Group controlId="currencySelect">
//     //                 <Form.Label>Select Currency</Form.Label>
//     //                 <Form.Control
//     //                   as="select"
//     //                   value={selectedCurrency}
//     //                   onChange={(e) => setSelectedCurrency(e.target.value)}
//     //                   required
//     //                 >
//     //                   <option value="">Select Currency</option>
//     //                   {currencies.map((currency) => (
//     //                     <option key={currency.id} value={currency.id}>
//     //                       {currency.name} ({currency.symbol})
//     //                     </option>
//     //                   ))}
//     //                 </Form.Control>
//     //               </Form.Group>
//     //               <Button variant="primary" type="submit" className="mt-3">Submit</Button>
//     //             </Form>
//     //           </Modal.Body>
//     //         </Modal>
//     //       </div>
//     //     </div>
//     //   </div>
//     //   {/* <Transactions /> */}
//     // </div>

// <div className="container align-items-center justify-content-center" style={{backgroundColor: '#000033' }}>
// <div className="row align-items-center justify-content-center">
//   <div className="col-xl-6 col-md-8">
//     <img src='../images/golden-coin.jpg' alt='wallet Home' style={{ width: "100px", textAlign: "center", fontWeight: "bold" }} />
//     <div className="card-holder card shadow p-4 mb-5" style={{ backgroundColor: '#000033', color: '#000033', fontSize: '14px', lineHeight: '1.4', borderRadius: '10px' }}>
//       <div className="wallet-widget" style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '10px', marginBottom: '15px' }}>
//         <div className="d-flex justify-content-between align-items-center mb-3">
//           <h5 style={{ fontWeight: 600, color: '#fff', backgroundColor: "#000033",  }}>Welcome, {user?.firstName} {user?.lastName}</h5>
//           <FaWallet size={24} />
//         </div>

//         <div className="d-flex justify-content-between mb-2">
//           <span className="text-bold" style={{ color: '#fff', backgroundColor: "#000033",  }}>Subscription Plan:</span>
//           <span style={{ color: '#fff', backgroundColor: "#000033",  }}><FaLock className="me-2" /> {selectedSubscription}</span>
//         </div>

//         <Form.Group controlId="subscriptionSelect">
//           <Form.Label className="text-primary text-uppercase mb-1" style={{ backgroundColor: "#000033", color: "#f0e000" }}>
//             Select Subscription Plan
//           </Form.Label>
//           <Form.Control as="select" style={{ backgroundColor: '#000033', color: '#FFF', padding: '8px', borderRadius: '5px' }} value={selectedSubscription} onChange={handleSubscriptionChange}>
//             {Object.keys(subscriptions).length > 0 ? (
//               Object.keys(subscriptions).map(planName => (
//                 <option key={planName} value={planName}>
//                   {planName}
//                 </option>
//               ))
//             ) : (
//               <option value="">No Plans Available</option>
//             )}
//           </Form.Control>
//         </Form.Group>

//         {selectedPlan && (
//           <>
//             <div className="d-flex justify-content-between align-items-center mb-3">
//               <span className="text-bold" style={{ color: '#000033',  }}>Deposit Amount</span>
//               <span style={{ color: '#fff', backgroundColor: "#000033",  }}><FaArrowDown className="me-2 text-success" /> ${selectedPlan.deposit.amount}</span>
//             </div>
//             <div className="d-flex justify-content-between align-items-center mb-3">
//               <span className="text-bold" style={{ color: '#000033' }}>Currency</span>
//               <span style={{ color: '#fff', backgroundColor: "#000033",  }}><FaArrowDown className="me-2 text-success" /> {selectedPlan.currency.currencyName} ({selectedPlan.currency.symbol})</span>
//             </div>
//             <div className="wallet-widget shadow-sm p-3" style={{ backgroundColor: "#000033", color: "#fff", backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('./images/golden-coin.jpg')" }}>
//               <p>Total Amount: ${selectedPlan.totalAmount}</p>
//               <p>Plan Start Date: {new Date(selectedPlan.startDate).toLocaleDateString()}</p>
//               <p>Plan End Date: {new Date(selectedPlan.endDate).toLocaleDateString()}</p>
//               <p>Wallet Deposit Status: {selectedPlan.deposit.status}</p>
//               <div className="d-flex justify-content-between align-items-center mb-3">
//                 <span className="text" style={{ color: 'green' }}>Total Invested Balance</span>
//                 <span style={{ color: 'green', fontWeight: 'bolder' }}><FaArrowDown className="me-2 text-success" style={{ color: 'green' }} /> ${selectedWallet?.balance || '0.00'}</span>
//               </div>
//               <div className="button-group" style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
//                 <Button variant="outline-primary" onClick={handleWithdrawShow} style={{ width: '100%', borderRadius: '8px', padding: '10px' }}>Withdraw</Button>
//                 <Button variant="outline-primary" onClick={handleDepositShow} style={{ width: '100%', borderRadius: '8px', padding: '10px' }}>Deposit</Button>
//               </div>
//             </div>
//           </>
//         )}
//       </div>

//       {/* Deposit Modal */}
//       <Modal show={showDepositModal} onHide={handleDepositClose}>
//         <Modal.Header closeButton style={{ borderBottom: 'none' }}>
//           <Modal.Title style={{ fontSize: '18px' }}>Deposit</Modal.Title>
//         </Modal.Header>
//         <Modal.Body style={{ padding: '20px' }}>
//           <Form onSubmit={handleDeposit}>
//             <Form.Group controlId="depositAmount">
//               <Form.Label>Deposit Amount</Form.Label>
//               <Form.Control type="number" placeholder="Enter deposit amount" value={depositAmount} onChange={(e) => setDepositAmount(e.target.value)} required />
//             </Form.Group>
//             <Form.Group controlId="currencySelect">
//               <Form.Label>Select Currency</Form.Label>
//               <Form.Control as="select" value={selectedCurrency} onChange={(e) => setSelectedCurrency(e.target.value)} required>
//                 <option value="">Select Currency</option>
//                 {currencies.map((currency) => (
//                   <option key={currency.id} value={currency.id}>
//                     {currency.name} ({currency.symbol})
//                   </option>
//                 ))}
//               </Form.Control>
//             </Form.Group>
//             <Form.Group controlId="proofOfPayment">
//               <Form.Label>Proof of Payment</Form.Label>
//               <Form.Control type="file" accept="image/*" onChange={handleFileChange} />
//             </Form.Group>
//             <Button variant="primary" type="submit" className="mt-3" style={{ backgroundColor: '#f0e000', color: '#000', padding: '10px', fontSize: '16px', borderRadius: '8px' }}>Submit</Button>
//           </Form>
//         </Modal.Body>
//       </Modal>

//       {/* Withdraw Modal */}
//       <Modal show={showWithdrawModal} onHide={handleWithdrawClose}>
//         <Modal.Header closeButton style={{ borderBottom: 'none' }}>
//           <Modal.Title style={{ fontSize: '18px' }}>Withdraw</Modal.Title>
//         </Modal.Header>
//         <Modal.Body style={{ padding: '20px' }}>
//           <Form onSubmit={handleWithdraw}>
//             <Form.Group controlId="withdrawAmount">
//               <Form.Label>Withdraw Amount</Form.Label>
//               <Form.Control type="number" placeholder="Enter withdrawal amount" value={withdrawAmount} onChange={(e) => setWithdrawAmount(e.target.value)} required />
//             </Form.Group>
//             <Form.Group controlId="currencySelect">
//               <Form.Label>Select Currency</Form.Label>
//               <Form.Control as="select" value={selectedCurrency} onChange={(e) => setSelectedCurrency(e.target.value)} required>
//                 <option value="">Select Currency</option>
//                 {currencies.map((currency) => (
//                   <option key={currency.id} value={currency.id}>
//                     {currency.name} ({currency.symbol})
//                   </option>
//                 ))}
//               </Form.Control>
//             </Form.Group>
//             <Button variant="primary" type="submit" className="mt-3" style={{ backgroundColor: '#f0e000', color: '#000', padding: '10px', fontSize: '16px', borderRadius: '8px' }}>Submit</Button>
//           </Form>
//         </Modal.Body>
//       </Modal>
//     </div>
//   </div>
// </div>
// </div>

//   );
// };

// export default WalletCard;



// import React, { useEffect, useState } from 'react';
// import { Button, Modal, Form } from 'react-bootstrap';
// import { FaArrowDown, FaWallet, FaLock } from 'react-icons/fa';
// import { useAuth } from './../AuthContext';
// import { useNavigate } from 'react-router-dom';
// import Swal from 'sweetalert2';
// import axios from 'axios';
// import styled from 'styled-components';

// const Container = styled.div`
//   background-color: #000033;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 20px;

//   @media (max-width: 768px) {
//     padding: 10px;
//   }
// `;

// const CardHolder = styled.div`
//   background-color: #000033;
//   color: #fff;
//   border-radius: 10px;
//   padding: 20px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
// `;

// const WalletWidget = styled.div`
//   background-color: #fff;
//   padding: 20px;
//   border-radius: 10px;
//   margin-bottom: 15px;

//   @media (max-width: 768px) {
//     padding: 15px;
//   }
// `;

// const Title = styled.h5`
//   font-weight: 600;
//   color: #fff;
// `;

// const Select = styled(Form.Control)`
//   background-color: #000033;
//   color: #fff;
//   padding: 8px;
//   border-radius: 5px;

//   @media (max-width: 768px) {
//     padding: 6px;
//   }
// `;

// const DepositWithdrawButton = styled(Button)`
//   margin-right: 10px;

//   @media (max-width: 768px) {
//     margin-right: 5px;
//   }
// `;

// const WalletCard = () => {
//   const { state } = useAuth();
//   const user = state?.user?.user;
//   const userId = user?.id;
//   const navigate = useNavigate();

//   const [subscriptions, setSubscriptions] = useState({});
//   const [selectedSubscription, setSelectedSubscription] = useState('');
//   const [selectedPlan, setSelectedPlan] = useState(null);
//   const [showDepositModal, setShowDepositModal] = useState(false);
//   const [showWithdrawModal, setShowWithdrawModal] = useState(false);
//   const [depositAmount, setDepositAmount] = useState('');
//   const [withdrawAmount, setWithdrawAmount] = useState('');
//   const [selectedCurrency, setSelectedCurrency] = useState('');
//   const [currencies, setCurrencies] = useState([]);
//   const [proofOfPayment, setProofOfPayment] = useState(null);
//   const [selectedWallet, setSelectedWallet] = useState(null);

//   useEffect(() => {
//     if (!userId) {
//       Swal.fire('Error', 'User not authenticated', 'error');
//       return;
//     }

//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`https://server.digitalspayout.com/api/crypto/wallet/user-wallet-subscription-details/${userId}`);
//         if (response.data.success) {
//           const data = response.data.data;
//           setSubscriptions(data.subscriptions || {});
//           if (Object.keys(data.subscriptions).length > 0) {
//             const firstPlan = Object.keys(data.subscriptions)[0];
//             setSelectedSubscription(firstPlan);
//             setSelectedPlan(data.subscriptions[firstPlan][0] || null);
//             setSelectedWallet(data.wallets[firstPlan]?.[0] || null);
//           }
//           const currenciesResponse = await axios.get('https://server.digitalspayout.com/api/crypto/currencies');
//           setCurrencies(currenciesResponse.data.data || []);
//         } else {
//           Swal.fire('Error', response.data.message, 'error');
//         }
//       } catch (error) {
//         Swal.fire('Error', 'Failed to fetch data', 'error');
//       }
//     };

//     fetchData();
//   }, [userId]);

//   const handleSubscriptionChange = (e) => {
//     const planName = e.target.value;
//     setSelectedSubscription(planName);
//     setSelectedPlan(subscriptions[planName]?.[0] || null);
//   };

//   const handleDepositShow = () => setShowDepositModal(true);
//   const handleDepositClose = () => setShowDepositModal(false);
//   const handleWithdrawShow = () => setShowWithdrawModal(true);
//   const handleWithdrawClose = () => setShowWithdrawModal(false);
//   const handleFileChange = (e) => setProofOfPayment(e.target.files[0]);

//   const handleDeposit = async (e) => {
//     e.preventDefault();
//     if (!selectedPlan) {
//       Swal.fire('Error', 'Please select a valid subscription plan', 'error');
//       return;
//     }
//     const formData = new FormData();
//     formData.append('amount', depositAmount);
//     formData.append('currencyId', selectedCurrency);
//     formData.append('walletId', selectedPlan.deposit.id);
//     formData.append('userId', userId);
//     if (proofOfPayment) {
//       formData.append('proofOfPayment', proofOfPayment);
//     }
//     try {
//       const response = await axios.post('https://server.digitalspayout.com/api/crypto/wallet/deposit', formData, {
//         headers: { 'Content-Type': 'multipart/form-data' }
//       });
//       if (response.data.success) {
//         Swal.fire('Success', 'Deposit completed successfully', 'success');
//         setShowDepositModal(false);
//       } else {
//         Swal.fire('Error', response.data.message, 'error');
//       }
//     } catch (error) {
//       Swal.fire('Error', 'Failed to complete the deposit', 'error');
//     }
//   };

//   const handleWithdraw = async (e) => {
//     e.preventDefault();
//     if (!selectedPlan) {
//       Swal.fire('Error', 'Please select a valid subscription plan', 'error');
//       return;
//     }
//     try {
//       const response = await axios.post('https://server.digitalspayout.com/api/crypto/payout/request', {
//         userId,
//         walletId: selectedPlan.deposit.id,
//         amount: withdrawAmount,
//         currencyId: selectedCurrency
//       });
//       if (response.data.success) {
//         Swal.fire('Success', 'Withdrawal completed successfully', 'success');
//         setShowWithdrawModal(false);
//       } else {
//         Swal.fire('Error', response.data.message, 'error');
//       }
//     } catch (error) {
//       Swal.fire('Error', 'Failed to complete the withdrawal', 'error');
//     }
//   };

//   return (
//     <Container>
//       <div className="col-xl-6 col-md-8">
//         <img src='../images/golden-coin.jpg' alt='wallet Home' style={{ width: "100px", textAlign: "center", fontWeight: "bold" }} />
//         <CardHolder>
//           <WalletWidget>
//             <div className="d-flex justify-content-between align-items-center mb-3">
//               <Title>Welcome, {user?.firstName} {user?.lastName}</Title>
//               <FaWallet size={24} />
//             </div>
//             <div className="d-flex justify-content-between mb-2">
//               <span className="text-bold">Subscription Plan:</span>
//               <span><FaLock className="me-2" /> {selectedSubscription}</span>
//             </div>
//             <Form.Group controlId="subscriptionSelect">
//               <Form.Label className="text-primary text-uppercase mb-1">Select Subscription Plan</Form.Label>
//               <Select as="select" value={selectedSubscription} onChange={handleSubscriptionChange}>
//                 {Object.keys(subscriptions).length > 0 ? (
//                   Object.keys(subscriptions).map(planName => (
//                     <option key={planName} value={planName}>{planName}</option>
//                   ))
//                 ) : (
//                   <option value="">No Plans Available</option>
//                 )}
//               </Select>
//             </Form.Group>
//             {selectedPlan && (
//               <>
//                 <div className="d-flex justify-content-between align-items-center mb-3">
//                   <span className="text-bold">Deposit Amount</span>
//                   <span><FaArrowDown className="me-2 text-success" /> ${selectedPlan.deposit.amount}</span>
//                 </div>
//                 <div className="d-flex justify-content-between align-items-center mb-3">
//                   <span className="text-bold">Currency</span>
//                   <span><FaArrowDown className="me-2 text-success" /> {selectedPlan.currency.currencyName} ({selectedPlan.currency.symbol})</span>
//                 </div>
//                 <div className="wallet-widget shadow-sm p-3" style={{ backgroundColor: "#000033", color: "#fff" }}>
//                   <p>Total Amount: ${selectedPlan.totalAmount}</p>
//                   <p>Plan Start Date: {new Date(selectedPlan.startDate).toLocaleDateString()}</p>
//                   <p>Plan End Date: {new Date(selectedPlan.endDate).toLocaleDateString()}</p>
//                   <p>Wallet Deposit Status: {selectedPlan.deposit.status}</p>
//                   <div className="d-flex justify-content-between align-items-center mb-3">
//                     <span>Total Invested Balance</span>
//                     <span><FaArrowDown className="me-2 text-success" /> ${selectedPlan.totalInvested}</span>
//                   </div>
//                 </div>
//                 <div className="d-flex justify-content-center mt-3">
//                   <DepositWithdrawButton variant="success" onClick={handleDepositShow}>Deposit</DepositWithdrawButton>
//                   <DepositWithdrawButton variant="danger" onClick={handleWithdrawShow}>Withdraw</DepositWithdrawButton>
//                 </div>
//               </>
//             )}
//           </WalletWidget>
//         </CardHolder>
//       </div>

//       {/* Deposit Modal */}
//       <Modal show={showDepositModal} onHide={handleDepositClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Deposit to Wallet</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form onSubmit={handleDeposit}>
//             <Form.Group controlId="formDepositAmount">
//               <Form.Label>Amount</Form.Label>
//               <Form.Control type="number" placeholder="Enter amount" value={depositAmount} onChange={(e) => setDepositAmount(e.target.value)} required />
//             </Form.Group>
//             <Form.Group controlId="formCurrencySelect">
//               <Form.Label>Currency</Form.Label>
//               <Select as="select" value={selectedCurrency} onChange={(e) => setSelectedCurrency(e.target.value)} required>
//                 <option value="">Select Currency</option>
//                 {currencies.map(currency => (
//                   <option key={currency.id} value={currency.id}>{currency.name} ({currency.symbol})</option>
//                 ))}
//               </Select>
//             </Form.Group>
//             <Form.Group controlId="formProofOfPayment">
//               <Form.Label>Proof of Payment (optional)</Form.Label>
//               <Form.File onChange={handleFileChange} />
//             </Form.Group>
//             <Button variant="primary" type="submit">Deposit</Button>
//           </Form>
//         </Modal.Body>
//       </Modal>

//       {/* Withdraw Modal */}
//       <Modal show={showWithdrawModal} onHide={handleWithdrawClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Withdraw from Wallet</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form onSubmit={handleWithdraw}>
//             <Form.Group controlId="formWithdrawAmount">
//               <Form.Label>Amount</Form.Label>
//               <Form.Control type="number" placeholder="Enter amount" value={withdrawAmount} onChange={(e) => setWithdrawAmount(e.target.value)} required />
//             </Form.Group>
//             <Form.Group controlId="formWithdrawCurrencySelect">
//               <Form.Label>Currency</Form.Label>
//               <Select as="select" value={selectedCurrency} onChange={(e) => setSelectedCurrency(e.target.value)} required>
//                 <option value="">Select Currency</option>
//                 {currencies.map(currency => (
//                   <option key={currency.id} value={currency.id}>{currency.name} ({currency.symbol})</option>
//                 ))}
//               </Select>
//             </Form.Group>
//             <Button variant="danger" type="submit">Withdraw</Button>
//           </Form>
//         </Modal.Body>
//       </Modal>
//     </Container>
//   );
// };

// export default WalletCard;


import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Spinner, Alert } from 'react-bootstrap'; // Add Spinner and Alert
import { FaArrowDown, FaWallet, FaLock } from 'react-icons/fa';
import { useAuth } from './../AuthContext';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import styled from 'styled-components';

const breakpoints = {
  xs: '0',       // Extra small devices (phones)
  sm: '576px',   // Small devices (tablets)
  md: '768px',   // Medium devices (desktops)
  lg: '992px',   // Large devices (large desktops)
  xl: '1200px',  // Extra large devices (larger desktops)
};

const Container = styled.div`
  background-color: #f5f0f2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 40px;
  justify-content: center;
  
  @media (max-width: 768px) {
   flex:2;
    padding: 20px;
  }

  @media (max-width: 480px) {
   flex: 1;
    padding: 10px;
  }

  @media (min-width: ${breakpoints.sm}) {
    padding: 40px;
  }
`;

const CardHolder = styled.div`
  background-color: #000033;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-bottom: 15px;
  width: 100%;
  max-width: 600px; // Limit card width
  height: 100vh;
  @media (min-width: ${breakpoints.md}) {
    padding: 30px;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 40px;
  }
`;

const WalletWidget = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
 border:2px solid #f0e00f;
  @media (max-width: ${breakpoints.sm}) {
    padding: 15px;
  }
`;

const Title = styled.h5`
  font-weight: 600;
  color: #fff;
  background-color: #000033;
`;

const Select = styled(Form.Control)`
  background-color: #000033;
  color: #FFF;
  padding: 8px;
  border-radius: 5px;
`;

const WalletCard = () => {
  const { state } = useAuth();
  const user = state?.user?.user;
  const userId = user?.id;
  const navigate = useNavigate();

  const [subscriptions, setSubscriptions] = useState({});
  const [selectedSubscription, setSelectedSubscription] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [depositAmount, setDepositAmount] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [currencies, setCurrencies] = useState([]);
  const [proofOfPayment, setProofOfPayment] = useState(null);
  const [selectedWallet, setSelectedWallet] = useState(null);
   
  const [loading, setLoading] = useState(true);  // Add loading state
  const [error, setError] = useState(null);      // Add error state

  useEffect(() => {
    if (!userId) {
      Swal.fire('Error', 'User not authenticated', 'error');
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
      
        const response = await axios.get(`https://server.digitalspayout.com/api/crypto/wallet/user-wallet-subscription-details/${userId}`);
        if (response.data.success) {
          const data = response.data.data;
          setSubscriptions(data.subscriptions || {});
          if (Object.keys(data.subscriptions).length > 0) {
            const firstPlan = Object.keys(data.subscriptions)[0];
            setSelectedSubscription(firstPlan);
            setSelectedPlan(data.subscriptions[firstPlan][0] || null);
            setSelectedWallet(data.wallets[firstPlan]?.[0] || null);
          }
          const currenciesResponse = await axios.get('https://server.digitalspayout.com/api/crypto/currencies');
          setCurrencies(currenciesResponse.data.data || []);
        } else {
          Swal.fire('Error', response.data.message, 'error');
          setError(response.data.message);
        }
      } catch (error) {
        Swal.fire('Error', 'Failed to fetch data', 'error');
        setError('Failed to fetch data');
      }
     finally {
      setLoading(false); // Set loading to false after data fetching
    }
    };

    fetchData();
  }, [userId]);



  const handleSubscriptionChange = (e) => {
    const planName = e.target.value;
    setSelectedSubscription(planName);
    setSelectedPlan(subscriptions[planName]?.[0] || null);
  };

  const handleDepositShow = () => setShowDepositModal(true);
  const handleDepositClose = () => setShowDepositModal(false);
  const handleWithdrawShow = () => setShowWithdrawModal(true);
  const handleWithdrawClose = () => setShowWithdrawModal(false);

  const handleFileChange = (e) => {
    setProofOfPayment(e.target.files[0]);
  };

  const handleDeposit = async (e) => {
    e.preventDefault();
    if (!selectedPlan) {
      Swal.fire('Error', 'Please select a valid subscription plan', 'error');
      return;
    }
    const formData = new FormData();
    formData.append('amount', depositAmount);
    formData.append('currencyId', selectedCurrency);
    formData.append('walletId', selectedPlan.deposit.id);
    formData.append('userId', userId);
    if (proofOfPayment) {
      formData.append('proofOfPayment', proofOfPayment);
    }
    try {
      const response = await axios.post('https://server.digitalspayout.com/api/crypto/wallet/deposit', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (response.data.success) {
        Swal.fire('Success', 'Deposit completed successfully', 'success');
        setShowDepositModal(false);
      } else {
        Swal.fire('Error', response.data.message, 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'Failed to complete the deposit', 'error');
    }
  };

  const handleWithdraw = async (e) => {
    e.preventDefault();
    if (!selectedPlan) {
      Swal.fire('Error', 'Please select a valid subscription plan', 'error');
      return;
    }
    try {
      const withdrawalData = {
        userId,
        walletId: selectedPlan.deposit.id,
        amount: withdrawAmount,
        currencyId: selectedCurrency
      }; 

      const response = await axios.post('https://server.digitalspayout.com/api/crypto/payout/request',withdrawalData );
      console.log('====================================');
      console.log('withdrawal req', response);
      console.log('====================================');
      if (response.data.success) {
        Swal.fire('Success', 'Withdrawal completed successfully', 'success');
        setShowWithdrawModal(false);
      } else {
        Swal.fire('Error', response.data.message, 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'Failed to complete the withdrawal', 'error');
    }
  };

  // const navigate = useNavigate();

  const handleNavigateToSubscription = () => {
    navigate('/dashboard/subscription-plan');
  };

   // Function to calculate total deposited balance
  //  const calculateTotalBalance = () => {
  //   let totalBalance = 0;
  //   Object.values(subscriptions).forEach((plans) => {
  //     plans.forEach((plan) => {
  //       if (plan.deposit && plan.deposit.amount) {
  //         totalBalance += parseFloat(plan.deposit.amount);
  //       }
  //     });
  //   });
  //   return totalBalance.toFixed(2); // Format to 2 decimal places
  // };
  // Function to calculate total deposited balance
const calculateTotalBalance = () => {
  let totalBalance = 0;

  Object.values(subscriptions).forEach((plans) => {
    plans.forEach((plan) => {
      // Check if the plan has a deposit and if the deposit amount is valid
      if (plan.deposit && plan.deposit.amount) {
        totalBalance += parseFloat(plan.deposit.amount);
      } else {
        // If no deposit or amount, treat as 0.00
        totalBalance += 0.00;
      }
    });
  });

  return totalBalance.toFixed(2); // Format to 2 decimal places
};


  
  if (loading) {
    return <Spinner animation="border" variant="primary" />;  // Display loading spinner
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;  // Display error alert
  }

  return (
    <Container>
      <div className="col-12">
        <CardHolder>
        <img src='../images/golden-coin.jpg' alt='wallet Home' style={{ width: "100px", textAlign: "center", fontWeight: "bold" }} />
          <WalletWidget>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Title>Welcome, {user?.firstName} {user?.lastName}</Title>
              <FaWallet size={24} />
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
            <span className="text" style={{ color: 'green' }}>Total Balance</span>
            <span style={{ color: 'green', fontWeight: 'bolder' }}>
              <FaArrowDown className="me-2 text-success" style={{ color: 'green' }} /> 
              ${calculateTotalBalance()}
            </span>
          </div>

            
            <div className="d-flex justify-content-between mb-2">
              <span className="text-bold" style={{ color: '#000033' }}>Subscription Plan:</span>
              <span style={{ color: '#000033' }}><FaLock className="me-2" /> {selectedSubscription}</span>
            </div>

            <Form.Group controlId="subscriptionSelect">
              <Form.Label className="text-primary text-uppercase mb-1" style={{ color: "#f0e000", fontSize:9 }}>
                Select Subscription Plan
              </Form.Label>
              <Select as="select" value={selectedSubscription} onChange={handleSubscriptionChange}>
                {Object.keys(subscriptions).length > 0 ? (
                  Object.keys(subscriptions).map(planName => (
                    <option key={planName} value={planName}>
                      {planName}
                    </option>
                  ))
                ) : (
                  <option value="">No Plans Available</option>
                )}
              </Select>
            </Form.Group>

            {selectedPlan && (
              <>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <span className="text-bold"  style={{ color: '#000033' }}>Deposit Amount</span>
                  <span style={{ color: '#000033', fontWeight:'bolder' }}><FaArrowDown className="me-2 text-success" /> ${selectedPlan.deposit.amount}</span>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <span className="text-bold"  style={{ color: '#000033' }}>Currency</span>
                  {/* <span style={{ color: '#000033' }}><FaArrowDown className="me-2 text-success" /> {selectedPlan.deposit.currency}</span> */}
                  <span style={{ color: '#000033' }}><FaArrowDown className="me-2 text-success" /> {selectedPlan.currency.currencyName} ({selectedPlan.currency.symbol})</span>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex justify-content-between align-items-center mb-3">
                <span className="text" style={{ color: 'green' }}>Total Investment Balance</span>
                <span style={{ color: 'green', fontWeight: 'bolder' }}><FaArrowDown className="me-2 text-success" style={{ color: 'green' }} /> 
                   ${calculateTotalBalance()}
                </span>
              </div>
              
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <span className="text-bold"  style={{ color: '#000033' }}>Admin Approval:</span>
                  <p  className="mb-0" style={{ color: '#000033' }}>{selectedPlan.deposit.status}</p>
                </div>
               
                <div className="button-group" style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                  {/* <Button variant="success outline-primary" onClick={handleDepositShow}>Deposit</Button> */}
                  <Button variant="success outline-primary" onClick={handleNavigateToSubscription}>
                    Deposit
                  </Button>
                  <Button variant="danger" onClick={handleWithdrawShow}>Withdraw</Button>
                </div>
              </>
            )}
          </WalletWidget>
        </CardHolder>

        {/* Deposit Modal */}
        <Modal show={showDepositModal} onHide={handleDepositClose}>
          <Modal.Header closeButton>
            <Modal.Title>Deposit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleDeposit}>
              <Form.Group controlId="depositAmount">
                <Form.Label>Amount</Form.Label>
                <Form.Control type="number" value={depositAmount} onChange={(e) => setDepositAmount(e.target.value)} required />
              </Form.Group>
              <Form.Group controlId="currencySelect">
                <Form.Label>Select Currency</Form.Label>
                <Select as="select" value={selectedCurrency} onChange={(e) => setSelectedCurrency(e.target.value)} required>
                  <option value="">Select Currency</option>
                  {currencies.map(currency => (
                    <option key={currency.id} value={currency.id}>{currency.name}</option>
                  ))}
                </Select>
              </Form.Group>
              <Form.Group controlId="proofOfPayment">
                <Form.Label>Proof of Payment</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} required />
              </Form.Group>
              <Button variant="primary" type="submit">Submit</Button>
            </Form>
          </Modal.Body>
        </Modal>

        {/* Withdraw Modal */}
        <Modal show={showWithdrawModal} onHide={handleWithdrawClose}>
          <Modal.Header closeButton>
            <Modal.Title>Withdraw</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleWithdraw}>
              <Form.Group controlId="withdrawAmount">
                <Form.Label>Amount</Form.Label>
                <Form.Control type="number" value={withdrawAmount} onChange={(e) => setWithdrawAmount(e.target.value)} required />
              </Form.Group>
              <Form.Group controlId="currencySelect">
                <Form.Label>Select Currency</Form.Label>
                <Select as="select" value={selectedCurrency} onChange={(e) => setSelectedCurrency(e.target.value)} required>
                  <option value="">Select Currency</option>
                  {currencies.map(currency => (
                    <option key={currency.id} value={currency.id}>{currency.name}</option>
                  ))}
                </Select>
              </Form.Group>
              <Button variant="primary" type="submit">Submit</Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </Container>
  );
};

export default WalletCard;
