// import React from 'react'

// function UserProfile() {
//   return (
//     <div>
//       hello
//     </div>
//   )
// }

// export default UserProfile


import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa';
import { useAuth } from './AuthContext';

const Container = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #000033;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const ProfileHeader = styled.h2`
  text-align: center;
  margin-bottom: 30px;
  color: #fff;
`;

const ProfilePicContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const ProfilePic = styled.img`
  border-radius: 50%;
  width: 120px;
  height: 120px;
  border: 3px solid #f0e00f;
`;

const EditButton = styled(Button)`
  display: flex;
  align-items: center;
  background-color: #f0e00f;
  border: none;
  color: #f0effc;

  &:hover {
    background-color: #d4c90f;
    color: #fff;
  }
`;

const ProfileDetails = styled.div`
  margin: 20px 0;
`;

const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  &:last-child {
    border-bottom: none;
  }
`;

const DetailLabel = styled.span`
  font-weight: bold;
  color: #fff;
`;

const DetailValue = styled.span`
  color: #fff;
`;

const EditModalTitle = styled.h5`
  color: #000033;
  text-align: center;
`;

const UserProfile = () => {
    const { state } = useAuth();
    const user = state?.user?.user;
    const [showModal, setShowModal] = useState(false);
    const [userData, setUserData] = useState({});

    useEffect(() => {
        if (user) {
            setUserData({
                firstName: user.firstName || '',
                lastName: user.lastName || '',
                email: user.email || '',
                phone: user.phone || ''
            });
        }
    }, [user]);

    const handleEditClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`https://server.digitalspayout.com/api/user/update/${user.id}`, userData);
            if (response.data.success) {
                Swal.fire('Success', 'Profile updated successfully!', 'success');
                handleCloseModal();
            } else {
                Swal.fire('Error', 'Failed to update profile.', 'error');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            Swal.fire('Error', 'An error occurred while updating your profile.', 'error');
        }
    };

    return (
        <Container>
            <ProfileHeader>User Profile</ProfileHeader>
            <ProfilePicContainer>
                <ProfilePic 
                    src="https://via.placeholder.com/120" 
                    alt="Profile" 
                />
            </ProfilePicContainer>
            <ProfileDetails>
                <DetailItem>
                    <DetailLabel>First Name:</DetailLabel>
                    <DetailValue>{userData.firstName}</DetailValue>
                </DetailItem>
                <DetailItem>
                    <DetailLabel>Last Name:</DetailLabel>
                    <DetailValue>{userData.lastName}</DetailValue>
                </DetailItem>
                <DetailItem>
                    <DetailLabel>Email:</DetailLabel>
                    <DetailValue>{userData.email}</DetailValue>
                </DetailItem>
                <DetailItem>
                    <DetailLabel>Phone:</DetailLabel>
                    <DetailValue>{userData.phone}</DetailValue>
                </DetailItem>
            </ProfileDetails>
            <EditButton onClick={handleEditClick}>
                <FaEdit style={{ marginRight: '8px' }} /> Edit Profile
            </EditButton>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <EditModalTitle>Edit Profile</EditModalTitle>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="firstName"
                                value={userData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="lastName"
                                value={userData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={userData.email}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formPhone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                name="phone"
                                value={userData.phone}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" style={{ width: '100%', backgroundColor: '#000033', border: 'none' }}>
                            Save Changes
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default UserProfile;
