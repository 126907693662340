import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Ensure the path is correct

const PrivateRoute = ({ children }) => {
  const { state } = useAuth(); // Get the state from the AuthContext
  console.log("auth state:", state.isAuthenticated);

  // If the user is authenticated, render the children (protected component), otherwise redirect to login
  return state.isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;


// import React, {  } from 'react';
// import { Navigate } from 'react-router-dom';
// import { useAuth } from './AuthContext'; // Update the import path if needed


// const PrivateRoute = ({ element }) => {
//   const { state, isAuthenticated } = useAuth();
//   // const isAuthenticated = true;
//     console.log("auth state:", state.isAuthenticated);
 
//   return state.isAuthenticated || isAuthenticated ? element : <Navigate to="/login" />;
  
// };

// export default PrivateRoute;
